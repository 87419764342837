/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import cookie from "react-cookies";
import OwlCarousel from "react-owl-carousel2";
import "../../common/css/owl.carousel.css";
import ContentLoader from "react-content-loader";
import axios from "axios";
import airportsData from "./airport.json";
import getCityList from "./getCityList.json";
import getCountry from "./getCountry.json";
import getflightminprices from "./get-flight-min-prices.json";
import getActivities from "./getActivities.json";
import packages from "./package.json";
import getcityData from "./getcityData.json";
import getcities from "./getcities.json";
import getReviews from "./getReview.json";
import Select from "react-select";
import swap from "../../common/images/swap-icon.svg";
import FlightsIcon from "../../common/images/Flights.svg";
import ThingstodoIcon from "../../common/images/Thingstodo.svg";
import VacationIcon from "../../common/images/Vacation.svg";
import searchlight from "../../common/images/search-light.svg";
import "react-datepicker/dist/react-datepicker.css";
import "react-spring-bottom-sheet/dist/style.css";
import { Calendar, DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { format } from "date-fns";
import avholder from "../../common/images/placeholder.jpg";
import actinfo1 from "../../common/images/act-info1.jpg";
import heart from "../../common/images/heart.svg";
import heartfill from "../../common/images/heart-fill.svg";
import car from "../../common/images/car-icon.svg";
import ticketact from "../../common/images/ticket-activity.svg";
import hotel from "../../common/images/hotel-icon.svg";
import flight from "../../common/images/flight-takeoff.svg";
import destim from "../../common/images/europe.jpg";
import cabox from "../../common/images/ca-box-img.svg";
import step1 from "../../common/images/step1-icon.svg";
import step2 from "../../common/images/step2-icon.svg";
import step3 from "../../common/images/step3-icon.svg";
import bcal from "../../common/images/calender-banner.svg";
import bprofile from "../../common/images/banner-profile.svg";
import bluearrow from "../../common/images/bluearrow.svg";
import mobsearch from "../../common/images/search-blue.svg";
import { medal } from "../Helpers/Images";
import emoji from "../../common/images/emoji.svg";
import float1 from "../../common/images/float1.webp";
import float2 from "../../common/images/float2.webp";
import float3 from "../../common/images/float3.webp";
import float4 from "../../common/images/float4.webp";
import arc from "../../common/images/arc.svg";
import prarrow from "../../common/images/promt-arrow.svg";
import search from "../../common/images/search.svg";
import plusb from "../../common/images/plus-b.svg";
import minusb from "../../common/images/minus-b.svg";
import crossicon from "../../common/images/cross-icon.svg";
import stephome from "../../common/images/step-home.svg";
import stephome1 from "../../common/images/step-home-active.svg";
import StaySeparate from "./StaySeparate";
import {
  apiUrl,
  landingPageapiUrl,
  fligUrl,
  subApiurl,
  apiglobaltixMediaUrl,
  cookieConfig,
} from "../Helpers/Config";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import { BottomSheet } from "react-spring-bottom-sheet";
import SearchComponent from "./Search/SearchComponent";
import {
  showAlert,
  showLoader,
  hideLoader,
  showPriceValue,
  checkingDiscount,
} from "../Helpers/SettingHelper";
import { GET_CUSTOMER_DETAIL } from "../../actions";
import {
  Avatar,
  AvatarGroup,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  FormGroup,
} from "@mui/material";
import { Helmet } from "react-helmet";
import moment from "moment";
import SignupComponent from "../Layout/SignupComponent";
import BannerCarousel from "./BannerCarousel/BannerCarousel";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import "react-horizontal-scrolling-menu/dist/styles.css";
import {
  LeftArrow1,
  RightArrow1,
  onWheel,
} from "../Customerpackage/Availability/Arrows";
import InfoCarousel from "./InfoCarousel/InfoCarousel";
import SearchIcon from "@mui/icons-material/Search";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Search from "./Search";
import VacationImg from "../../common/images/vacation.png";
import mVacationImg from "../../common/images/m_vacation.svg";
import mCuratedImg from "../../common/images/M_Curated.svg";
import CuratedImg from "../../common/images/Curated.png";
import SwapImg from "../../common/images/Swap.svg";
import flightsImg from "../../common/images/Flights.png";
import mFlightsImg from "../../common/images/mFlights.svg";
import ttdImg from "../../common/images/ttd.png";
import mTtdImg from "../../common/images/TTD.svg";
import TickImg from "../../common/images/Tick.svg";
import watchImg from "../../common/images/Play icon.svg";
import companyGif from "../../common/images/Company.gif";
import commaReview from "../../common/images/Comma_Review.svg";
import locationIcon from "../../common/images/location.svg";
import FlightsSeprate from "../../common/images/Flights_Seprate.svg";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import Tabs from "./Tabs/Tabs";
import VideoPlayer from "./VideoPlayer/VideoPlayer";
import PackageLeadDialog from "../Customerpackage/PackageLeadModel/PackageLead";
var base64 = require("base-64");
var qs = require("qs");
var hypercustomOption = {
  items: 3,
  loop: true,
  dots: false,
  nav: false,
  margin: 20,
  responsive: {
    0: {
      items: 1,
      // margin: 10,
    },
    580: {
      items: 2,
      margin: 15,
    },
    980: {
      items: 3,
    },
  },
};
var trendoption = {
  items: 3,
  loop: true,
  dots: false,
  nav: false,
  margin: 20,
  responsive: {
    0: {
      items: 1,
      margin: 10,
    },
    580: {
      items: 2,
      margin: 15,
    },
    980: {
      items: 4,
    },
  },
};

var mobilesteps = {
  items: 2,
  loop: window.innerWidth <= 480,
  dots: false,
  nav: false,
  autoplay: window.innerWidth <= 480,
  margin: 10,
  stagePadding: window.innerWidth <= 480 ? 0 : 30,
  responsive: {
    0: {
      items: 1,
      margin: 10,
      stagePadding: window.innerWidth <= 480 ? 0 : 20,
    },
    480: {
      items: 2,
      margin: 10,
      stagePadding: window.innerWidth <= 480 ? 0 : 20,
    },
  },
};
var destoption = {
  items: 4,
  loop: true,
  dots: false,
  nav: false,
  margin: 25,
  stagePadding: 40,
  responsive: {
    0: {
      items: 1,
      margin: 10,
      stagePadding: 20,
    },
    580: {
      items: 2,
      margin: 20,
      stagePadding: 30,
    },
    980: {
      items: 4,
      stagePadding: 40,
    },
  },
};
var min_date = new Date();
min_date.setDate(min_date.getDate() + 1);
var childAgeOptions = [];
for (let index = 2; index <= 12; index++) {
  childAgeOptions.push({ label: index, value: index });
}
var date = new Date();
date.setDate(date.getDate() + 15);
var formattedDate = date.toDateString() + " " + date.toTimeString();
var start_date = new Date(formattedDate);
var apidate = new Date();
apidate.setDate(apidate.getDate() + 15);
var formattedDate = apidate.toISOString().split("T")[0];
var api_start_date = formattedDate;
var endDate = new Date();
endDate.setDate(endDate.getDate() + 22);
var formattedEndDate = endDate.toDateString() + " " + endDate.toTimeString();
var end_date = new Date(formattedEndDate);
var dayoption = ["2-5", "6-8", "9-12", "13-15"];
var ttdPack = "396,419,571,582,622,624,618,382"; // Testing Packages
var budgetPack = "84, 183, 187, 165, 166, 171, 104, 73, 224, 140,146";
var discountPack = "252,253,254"; // Testing Packages
var holidayPack =
  "277, 93, 203,  127, 120, 154, 139, 149, 200, 190, 199, 173, 254, 238";
var topPack = "290, 289, 288";

const priceFilterOptions = [
  {
    label: "Below S$500",
    value: "500-",
  },
  {
    label: "S$500 - S$1000",
    value: "500-1000",
  },
  {
    label: "S$2000 - S$5000",
    value: "2000-5000",
  },
  {
    label: "Above S$5000",
    value: "-5000",
  },
];
let count;
const getNextYearMonths = () => {
  const today = new Date();
  const nextYear = today.getFullYear();

  const nextYearDates = [];
  for (let i = 0; i < 12; i++) {
    const nextMonthDate = new Date(nextYear, today.getMonth() + i, 1);
    const formattedDate = getMonthYearFormat(nextMonthDate);
    nextYearDates.push({
      label: formattedDate.split("-")[0],
      value: formattedDate,
    });
  }

  return nextYearDates;
};
const getMonthYearFormat = (date) => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const month = months[date.getMonth()];
  const year = date.getFullYear();

  return `${month}-${year}`;
};
const nextYearMonths = getNextYearMonths();
class Home extends React.PureComponent {
  constructor(props) {
    super(props);
    var selectedDestinationdetails = "";
    this.carouselRef = React.createRef(null);
    this.dropdownRef = React.createRef();
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    var step = 1;
    if (
      this.props.location.state !== "" &&
      this.props.location.state !== null &&
      typeof this.props.location.state !== undefined &&
      typeof this.props.location.state !== "undefined"
    ) {
      var searchdata = this.props.location.state;
      selectedDestinationdetails =
        searchdata.selectedDestinationdetails !== "" &&
        typeof searchdata.selectedDestinationdetails !== undefined &&
        typeof searchdata.selectedDestinationdetails !== "undefined"
          ? searchdata.selectedDestinationdetails
          : "";
      if (selectedDestinationdetails !== "") {
        step = 3;
      }
    }
    if (
      this.props.location.state !== "" &&
      typeof this.props.location.state !== undefined &&
      typeof this.props.location.state !== "undefined"
    ) {
      var redirected = this.props.location.state.redirected;
      var creator_package_total_days =
        this.props.location.state.creator_package_total_days;
      var siteUrl = this.props.location.state.siteUrl;
    } else {
      var redirected = false;
      var siteUrl = "";
      var creator_package_total_days = 0;
    }
    var resetKey =
      this.props.match.params !== "" &&
      typeof this.props.match.params !== undefined &&
      typeof this.props.match.params !== "undefined" &&
      this.props.match.params.resetKey !== "" &&
      typeof this.props.match.params.resetKey !== undefined &&
      typeof this.props.match.params.resetKey !== "undefined"
        ? this.props.match.params.resetKey
        : "";
    var isFlight = false;
    if (
      this.props.location.state !== "" &&
      typeof this.props.location.state !== undefined &&
      typeof this.props.location.state !== "undefined"
    ) {
      isFlight = this.props.location.state.isFlight;
    }
    var isVacation = true;
    if (
      this.props.location.state !== "" &&
      typeof this.props.location.state !== undefined &&
      typeof this.props.location.state !== "undefined"
    ) {
      isVacation = this.props.location.state.isVacation;
    }
    var isActivities = false;
    if (
      this.props.location.state !== "" &&
      typeof this.props.location.state !== undefined &&
      typeof this.props.location.state !== "undefined"
    ) {
      isActivities = this.props.location.state.isActivities;
    }
    var isStay = false;
    if (
      this.props.location.state !== "" &&
      typeof this.props.location.state !== undefined &&
      typeof this.props.location.state !== "undefined"
    ) {
      isStay = this.props.location.state.isStay;
    }
    this.state = {
      loading: false,
      customerdetail: "",
      source_list: [],
      destination_list: [],
      location_source: "",
      source_airport_code: "",
      source_airport_id: "",
      source_airport_address: "",
      source_airport_lat: "",
      source_airport_lon: "",
      location_destination: "",
      destination_airport_code: "",
      destination_airport_id: "",
      destination_airport_address: "",
      destination_airport_lat: "",
      destination_airport_lon: "",
      start_date: "",
      end_date: "",
      search_days: "",
      adults: 1,
      child: 0,
      infant: 0,
      childAgeList: [],
      openpassengerList: false,
      passengererror: "",
      error_location_source: false,
      error_location_destination: false,
      error_start_date: false,
      error_month: false,
      error_end_date: false,
      error_child: false,
      error_airport_source: false,
      error_airport_destination: false,
      airport: [],
      selectedairport: [],
      source_address: "",
      source_packagecountry: "",
      source_packageCity: "",
      source_pacakgeLat: "",
      source_packageLon: "",
      destination_airport: [],
      destination_selectedairport: [],
      destination_address: "",
      destination_packagecountry: "",
      destination_packageCity: "",
      destination_pacakgeLat: "",
      destination_packageLon: "",
      room_count: 1,
      isMobile: window.innerWidth <= 480,
      mainCityList: [],
      cityList: [],
      searchkeywords: "",
      searchCityList: [],
      city_List_carousel: {},
      step: step,
      mobileStep: 1,
      selectedDestinationdetails: selectedDestinationdetails,
      selectionRange: {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
      dateRange: {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
      openFromDateRange: false,
      openToDateRange: false,
      totalTripDays: 0,
      highlightcountry: [],
      countryList: [],
      searchcountrykeywords: "",
      searchCountryList: [],
      selectcountry: false,
      searchairport: "",
      searchairportkeywords: "",
      redirected: redirected,
      siteUrl: siteUrl,
      checkinTime: "",
      creator_package_total_days: creator_package_total_days,
      inputFocused: false,
      searchedDays: "",
      days_count: "",
      resetKey: resetKey,
      passwordType: "password",
      cpasswordType: "password",
      new_password: "",
      confirm_password: "",
      new_passwordError: "",
      confirm_passwordError: "",
      subMail: "",
      submsg: "",
      subvalid: false,
      emailvalid: false,
      Errorpop: false,
      isFlight: isFlight,
      isActivities: isActivities,
      isVacation: isVacation,
      isStay: isStay,
      trip_type: "Return",
      swaproute: false,
      fromCityInput: "",
      toCityInput: "",
      fromCityList: [],
      toCityList: [],
      passengerPopup: false,
      homePackages: [],
      display_home_package: {},
      showHomePackages: true,
      sourceCityItem: [],
      desCityItem: [],
      ttdOnly: false,
      sourceCity: "",
      destinationCity: "",
      selectedadult: "",
      homePackageLoading: true,
      isHovered: null,
      displaySearchCountry: "",
      displaySearchProducts: "",
      showsearchList: false,
      showDateError: false,
      packageType: "vacation",
      showPackageTypeError: false,
      selectedMonth: "",
      showDatePopup: false,
      destinationList: [],
      showDestinationPopup: false,
      openSearchPopup: false,
      selectedDestination: false,
      opendestinationList: false,
      price: null,
      showSearchBox: false,
      openSearchBottomSheet: false,
      openCalendarBottomSheet: false,
      currentCurrency: cookie.load("currentCurrency") || "SGD",
      currencyRate: {},
      selectedTab: "vacation",
      recommendDestinations: [],
      flightRoutes: [],
      popularDestinations: [],
      reviews: [],
      display_ttd_package: {},
      ttdPackages: {},
      openFlightSearchSheet: null,
      ttdCityData: [],
      showCustomizeVideo: false,
      showPackageLeadPopup: false,
      selectedItem: {},
      airportsData: airportsData,
      getCityList: getCityList,
      getCountry: getCountry,
      getflightminprices: getflightminprices,
      getActivities: getActivities,
      LandingPackages: packages,
      getcityData: getcityData,
      getcities: getcities,
      getReviews: getReviews,
      hyper_packages: "",
      source_airport_error: "",
      destination_airport_error: "",
      departure_airport_error: "",
      fromdate_airport_error: "",
      todate_airport_error: "",
      toDateRange: false,
      fromDateRange: false,
      fromRange: false,
      recentSearchesList: [],
      showSearchResults: false,
      shouldLoad: false,
      selectAutoPackageLeadPopup: false,
    };
    this.cityFromRef = React.createRef();
    this.cityToRef = React.createRef();
    this.daterangeRef = React.createRef();
    this.passengerListRef = React.createRef();
    this.openFromDateRangeRef = React.createRef();
    this.openToDateRangeRef = React.createRef();
  }
  componentDidMount() {
    this.loadJson();
    window.addEventListener("scroll", this.handleScroll);
    this.getDestinations();
    this.loadCountry();
    this.getReviews();
    this.getActivities();
    this.getTtdCityData();
    this.getPopularFlightRoutes();
    if (cookie.load("openSearch") === "Yes") {
      $.magnificPopup.open({
        items: {
          src: "#search-popup",
        },
        type: "inline",
        closeOnBgClick: true,
        showCloseBtn: false,
        midClick: true,
      });
      cookie.remove("openSearch", cookieConfig);
    }

    this.getAllairPorts("source");
    this.loadCity();
    $(document).ready(function () {
      function disablePrev() {
        window.history.forward();
      }
      window.onload = disablePrev();
      window.onpageshow = function (evt) {
        if (evt.persisted) disableBack();
      };
    });
    if (
      cookie.load("acccesstoken") !== "" &&
      typeof cookie.load("acccesstoken") !== undefined &&
      typeof cookie.load("acccesstoken") !== "undefined"
    ) {
      if (this.state.redirected) {
        if (this.state.isMobile) {
          this.setState({ openBottomSheet: true, mobileStep: 2 });
        } else {
          this.gotoNextStep(3);
        }
      }
      var currentThis = this;
      $(document).click(function (e) {
        if (
          !$(e.target).is(
            ".passenger_list, .passenger_list *, .css-10nd86i *, .css-15k3avv *"
          )
        ) {
          if ($(".passenger_list ul.active").length > 0) {
            currentThis.setState({ openpassengerList: false });
          }
        }
      });
      $(function () {
        var count = 0;
        var wordsArray = [
          "Experience",
          "Vacation",
          "Weekend getaway",
          "Staycation",
        ];
        setInterval(function () {
          count++;
          $(".word").fadeOut(400, function () {
            $(this)
              .text(wordsArray[count % wordsArray.length])
              .fadeIn(500);
          });
          $("#mobile-word").fadeOut(400, function () {
            $(this)
              .text(wordsArray[count % wordsArray.length])
              .fadeIn(500);
          });
        }, 3000);
      });

      this.loadInititalFN();

      this.setState({ inputFocused: true });
      if (this.state.isMobile) {
        $("#freshworks-container").hide();
      }

      if (this.state.resetKey !== "") {
        $.magnificPopup.open({
          items: {
            src: "#reset-password-popup",
          },
          type: "inline",
          closeOnBgClick: false,
        });
      }
      $("#reset-password-popup .mfp-close").click(function () {
        currentThis.props.history.push("/");
      });
    }
    window.addEventListener("scroll", this.changeNavBg);

    document.addEventListener("click", this.handleClickOutside);
  }

  loadInititalFN() {
    var acccesstoken = cookie.load("acccesstoken") || "";
    if (acccesstoken !== "") {
      /*    this.loadJson();
        this.loadCountry(); */
      /*    this.getPackage(); */
      /*    this.getReviews();
        this.getActivities();
        this.getTtdCityData();
        this.getPopularFlightRoutes(); */
    } else {
      setTimeout(() => {
        this.loadInititalFN();
      }, 1000);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState?.selectedTab !== this.state?.selectedTab) {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
    if (this.state.isMobile) {
      $("#freshworks-container").hide();
    }
  }
  getDestinations = async () => {
    try {
      const res = this.state.getcities?.cities;
      this.setState({ recommendDestinations: res });
    } catch (err) {
      console.log(err);
    }
  };
  getReviews = async () => {
    try {
      const res = this.state.getReviews?.reviews;
      this.setState({ reviews: res });
    } catch (err) {
      console.log(err);
    }
  };
  getActivities = async () => {
    try {
      const res = this.state.getActivities?.response;
      const $_currencyRate = this.state.getActivities?.currency;
      this.setState({ ttdPackages: res, currencyRate: $_currencyRate }, () => {
        this.displayTtdPackages();
      });
    } catch (err) {
      console.log(err);
    }
  };
  getTtdCityData = async () => {
    try {
      const res = this.state.getcityData?.data;
      this.setState({ ttdCityData: res });
    } catch (err) {
      console.log(err);
    }
  };
  getPopularFlightRoutes = async () => {
    const res = this.state.getflightminprices?.response;

    this.setState({ flightRoutes: res["Popular flight routes"] });
    if (Object.keys(res["Popular destinations"])?.length) {
      const obj = res["Popular destinations"];
      const newObj = {};
      Object.keys(obj)?.forEach((key) => {
        const carouselRef = React.createRef();
        newObj[key] = {
          segmentName: key,
          packages: obj[key],
          carouselRef: carouselRef,
        };
      });
      this.setState({ popularDestinations: newObj });
    }
  };
  handleWatchVideo = () => {
    this.setState({ showCustomizeVideo: !this.state.showCustomizeVideo });
  };
  getPackage = async () => {
    if (this.state.LandingPackages?.status === "ok") {
      var packageslist = this.state.LandingPackages?.response;
      this.setState(
        {
          homePackages: packageslist,
          currencyRate: this.state.LandingPackages?.currency,
          showHomePackages: true, // Set this to true here
          homePackageLoading: false, // Ensure this is false
        },
        () => {
          this.displayPackages();
        }
      );
    }
  };

  changeNavBg = () => {
    window.scrollY >= (this.state.isMobile ? 180 : 64)
      ? this.setState({ showSearchBox: true })
      : this.setState({ showSearchBox: false });
  };

  fetchCities = async (title) => {
    console.log(title, "title");
    const cityInput =
      title === "fromCityInput"
        ? this.state.fromCityInput
        : this.state.toCityInput;
    const lowerCaseCity = cityInput?.toLowerCase();

    // First, filter by exact airport_code match
    let filteredAirports = this.state.airportsData.filter(
      (airport) => airport.airport_code?.toLowerCase() === lowerCaseCity
    );

    // If no match by airport_code, continue filtering by other properties in priority order
    if (filteredAirports.length === 0) {
      filteredAirports = this.state.airportsData.filter(
        (airport) =>
          airport.airport_name?.toLowerCase().includes(lowerCaseCity) ||
          airport.airport_city_name?.toLowerCase().includes(lowerCaseCity) ||
          airport.airport_country?.toLowerCase().includes(lowerCaseCity) ||
          airport.airport_address?.toLowerCase().includes(lowerCaseCity)
      );
    }

    console.log(filteredAirports, "filteredAirports");

    const list = title === "fromCityInput" ? "fromCityList" : "toCityList";

    this.setState(
      {
        [list]: {
          searchResults: filteredAirports, // Updated search results based on priority
          allCities: this.state.airportsData, // All available cities
        },
        showSearchResults: true,
      },
      () => {
        if ($(".mCustomScrollbar").length > 0) {
          $(".mCustomScrollbar").mCustomScrollbar();
        }
      }
    );
  };

  applyQty(member, qtystatus) {
    var existQty;
    if (member === "adults") {
      existQty = this.state.adults;
    } else if (member === "child") {
      existQty = this.state.child;
    } else if (member === "infant") {
      existQty = this.state.infant;
    } else if (member === "room_count") {
      existQty = this.state.room_count;
    }
    if (member === "child" || member === "infant") {
      if (this.state.adults <= 0) {
        return false;
      }
    }
    var totalPassenger =
      parseInt(this.state.adults) +
      parseInt(this.state.child) +
      parseInt(this.state.infant);
    if (qtystatus == "M") {
      if (member === "adults") {
        existQty = parseInt(this.state.adults - 1);
        const adultrooms = Math.ceil(parseInt(this.state.adults - 1) / 2);
        const childrooms = Math.ceil(parseInt(this.state.child) / 2);
        const childR = childrooms - adultrooms;
        console.log(this.state.room_count, "room");
        if (childR > 0) {
          var requiredRooms = adultrooms + childR;
          const maxRooms = Math.min(requiredRooms, 9);
          if (maxRooms > this.state.room_count) {
            this.setState({ room_count: maxRooms });
          }
        } else if (childR <= 0) {
          const maxRooms = Math.min(adultrooms, 9);
          if (maxRooms > this.state.room_count) {
            this.setState({ room_count: maxRooms });
          }
        }
      }
      if (member === "child") {
        if (this.state.child >= 1) {
          existQty = parseInt(this.state.child - 1);
          const adultrooms = Math.ceil(parseInt(this.state.adults) / 2);
          const childrooms = Math.ceil(parseInt(this.state.child - 1) / 2);
          const childR = childrooms - adultrooms;
          if (childR > 0) {
            var requiredRooms = adultrooms + childR;
            const maxRooms = Math.min(requiredRooms, 9);
            console.log(requiredRooms, maxRooms, this.state.room_count, "room");
            this.setState({ room_count: maxRooms });
          } else if (childR <= 0) {
            const maxRooms = Math.min(adultrooms, 9);
            if (maxRooms > this.state.room_count) {
              this.setState({ room_count: maxRooms });
            }
          }
        }
      }
      if (member === "infant") {
        if (this.state.infant >= 1) {
          existQty = parseInt(this.state.infant - 1);
        }
      }
      if (member === "room_count") {
        existQty = parseInt(this.state.room_count - 1);
        const adultrooms = Math.ceil(parseInt(this.state.adults) / 2);
        const childrooms = Math.ceil(parseInt(this.state.child) / 2);
        const childR = childrooms - adultrooms;
        if (childR > 0) {
          var requiredRooms = adultrooms + childR;
          const maxRooms = Math.min(requiredRooms, 9);
          console.log(maxRooms, this.state.room_count, "room");
          if (this.state.room_count <= maxRooms) {
            return;
          }
          if (maxRooms > this.state.room_count) {
            this.setState({ room_count: maxRooms });
          }
        } else if (childR <= 0) {
          const maxRooms = Math.min(adultrooms, 9);
          if (this.state.room_count <= maxRooms) {
            return;
          }
          if (maxRooms > this.state.room_count) {
            this.setState({ room_count: maxRooms });
          }
        }
      }
    } else {
      if (member === "adults") {
        const adultrooms = Math.ceil(parseInt(this.state.adults + 1) / 2);
        const childrooms = Math.ceil(parseInt(this.state.child) / 2);
        const childR = childrooms - adultrooms;
        if (childR > 0) {
          var requiredRooms = adultrooms + childR;
          const maxRooms = Math.min(requiredRooms, 9);
          console.log(maxRooms, this.state.room_count, "room");
          if (maxRooms > this.state.room_count) {
            this.setState({ room_count: maxRooms });
          }
        } else if (childR <= 0) {
          const maxRooms = Math.min(adultrooms, 9);
          console.log(maxRooms, this.state.room_count, "room");
          if (maxRooms > this.state.room_count) {
            this.setState({ room_count: maxRooms });
          }
        }
      }
      if (member === "child") {
        const adultrooms = Math.ceil(parseInt(this.state.adults) / 2);
        const childrooms = Math.ceil(parseInt(this.state.child + 1) / 2);
        const childR = childrooms - adultrooms;
        if (childR > 0) {
          var requiredRooms = adultrooms + childR;
          const maxRooms = Math.min(requiredRooms, 9);
          console.log(maxRooms, this.state.room_count, "room");
          this.setState({ room_count: maxRooms });
        } else if (childR <= 0) {
          const maxRooms = Math.min(adultrooms, 9);
          console.log(maxRooms, this.state.room_count, "room");
          if (maxRooms > this.state.room_count) {
            this.setState({ room_count: maxRooms });
          }
        }
      }
      existQty = parseInt(existQty) + 1;
      totalPassenger = parseInt(totalPassenger) + 1;
    }
    if (totalPassenger >= 10) {
      this.setState({ passengererror: "Maximum 9 passengers only allow" });
      return false;
    } else {
      this.setState({ passengererror: "" });
    }
    if (parseInt(existQty) < 0) {
      existQty = 0;
    }

    if (member === "room_count") {
      if (this.state.room_count >= 9) {
        return;
      }
    }
    this.setState({ [member]: existQty, passengererror: "" }, function () {
      if (member === "child") {
        var existChildAgeList = this.state.childAgeList;
        var newChildAgeList = [];
        for (let index = 0; index < existQty; index++) {
          var newAgeList =
            existChildAgeList[index] !== "" &&
            typeof existChildAgeList[index] !== undefined &&
            typeof existChildAgeList[index] !== "undefined"
              ? this.state.childAgeList[index]
              : /* []; */ {};
          newChildAgeList.push(newAgeList);
        }
        this.setState({ childAgeList: newChildAgeList });
      }
    });
  }

  searchPackage() {
    var error = 0;
    if (this.state.adults === 0) {
      this.setState({ passengererror: "Please Select any one adult" });
      error++;
    } else if (this.state.child > 0) {
      const isEmpty = [...this.state.childAgeList].some(
        (obj) => Object.keys(obj).length === 0
      );
      if (isEmpty) {
        this.setState({ passengererror: "Please Select child age" });
        error++;
      } else {
        this.setState({ passengererror: false });
      }
    } else {
      this.setState({ passengererror: false });
    }
    if (this.state.childAgeList === 0) {
      this.setState({ error_child: true });
      error++;
    } else {
      this.setState({ error_child: false });
    }
    if (error === 0) {
      var packagedetails = {
        start_date: this.state.start_date || new Date(),
        end_date: this.state.end_date || new Date(),
        selectedMonth: this.state.selectedMonth,
        search_days: this.state.search_days,
        adults: this.state.adults,
        child: this.state.child,
        infant: this.state.infant,
        room_count: this.state.room_count,
        source_airport_code: this.state.source_airport_code,
        location_source: this.state.source_airport_address,
        source_airport_id: this.state.source_airport_id,
        source_airport_address: this.state.source_airport_code,
        source_airport_lat: this.state.source_airport_lat,
        source_airport_lon: this.state.source_airport_lon,
        destination_airport_code:
          this.state.selectedDestinationdetails.airport_code,
        location_destination:
          this.state.selectedDestinationdetails.airport_address,
        destination_airport_id:
          this.state.selectedDestinationdetails.airport_id,
        destination_airport_address:
          this.state.selectedDestinationdetails.airport_address,
        destination_airport_lat:
          this.state.selectedDestinationdetails.airport_latitude,
        destination_airport_lon:
          this.state.selectedDestinationdetails.airport_longitude,
        thumbnail: this.state.selectedDestinationdetails.thumbnail,
        images: this.state.selectedDestinationdetails.images,
        childAgeList: this.state.childAgeList,
        trip_type: "Return",
        transfer_trip_type: "Return",
        trip_count: this.state.days_count,
        sourceCountry: this.state.source_packagecountry,
        destinationCountry: this.state.selectedDestinationdetails.country_name,
        sourceCity: this.state.source_packageCity,
        destinationCity: this.state.selectedDestinationdetails.city_name,
        destinationWebbedsCity:
          this.state.selectedDestinationdetails.webbeds_city_code,
        sourceLat: this.state.source_airport_lat,
        sourceLon: this.state.source_airport_lon,
        destinationLat: this.state.selectedDestinationdetails.airport_latitude,
        destinationLon: this.state.selectedDestinationdetails.airport_longitude,
        airportLat: this.state.selectedDestinationdetails.airport_latitude,
        airportLon: this.state.selectedDestinationdetails.airport_longitude,
        packageType: "uop",
      };
      this.props.history.push({
        pathname: "/search-package",
        state: packagedetails,
      });
    }
  }

  onChangeChildAge(selectindex, value) {
    var new_Child_Age_List = [];
    if (this.state.childAgeList.length > 0) {
      this.state.childAgeList.map((item, index) => {
        if (index === selectindex) {
          new_Child_Age_List.push(value);
        } else {
          new_Child_Age_List.push(item);
        }
      });
    }
    this.setState({ childAgeList: new_Child_Age_List });
  }
  sateValChange = (field, value) => {
    if (field === "address") {
      if (value !== "") {
        var address = $("#pac-input").val();
        var addressdetails = value.split("~~");
        this.setState(
          {
            address: address,
            source_packagecountry: addressdetails[0],
            source_pacakgeLat: addressdetails[1],
            source_packageLon: addressdetails[2],
            source_packageCity: addressdetails[3],
          },
          function () {
            if (this.state.source_packagecountry !== "") {
              this.loadAirport("source", this.state.source_packagecountry);
            }
          }
        );
      }
    }
    if (field === "destinationaddress") {
      if (value !== "") {
        var address = $("#pac-input1").val();
        var addressdetails = value.split("~~");
        this.setState(
          {
            destination_address: address,
            destination_packagecountry: addressdetails[0],
            destination_pacakgeLat: addressdetails[1],
            destination_packageLon: addressdetails[2],
            destination_packageCity: addressdetails[3],
          },
          function () {
            if (this.state.destination_packagecountry !== "") {
              this.loadAirport(
                "destination",
                this.state.destination_packagecountry
              );
            }
          }
        );
      }
    }
    if (field === "cityList" && value !== "") {
      this.setState({
        cityList: value.result_set,
        mainCityList: value.main_city,
      });
    }
    if (field === "selectedDestinationdetails") {
      this.setState(
        {
          selectedDestinationdetails: value,
          step: this.state.isMobile ? 1 : 3,
        },
        function () {
          $.magnificPopup.close();
        }
      );
    }
    if (field === "isFlight") {
      this.setState(
        {
          isFlight: value,
          isActivities: false,
          isVacation: false,
          isStay: false,
        },
        () => this.props.history.push("/")
      );
    }
    if (field === "isActivities") {
      this.setState(
        {
          isActivities: value,
          isFlight: false,
          isVacation: false,
          isStay: false,
        },
        () => this.props.history.push("/")
      );
    }
    if (field === "isVacation") {
      this.setState(
        {
          isVacation: value,
          isFlight: false,
          isActivities: false,
          isStay: false,
        },
        () => this.props.history.push("/")
      );
    }
    if (field === "isStay") {
      this.setState(
        {
          isStay: value,
          isFlight: false,
          isActivities: false,
          isVacation: false,
        },
        () => this.props.history.push("/")
      );
    }
    if (field === "price") {
      this.setState({ price: value });
    }
    if (field === "currentCurrency") {
      this.setState({ currentCurrency: value }, () => {
        this.displayPackages();
        this.displayTtdPackages();
      });
    }
  };
  searchActivities = async (e) => {
    try {
      var name = e.target.name;
      var value = e.target.value;
      if (value !== "" && value.length > 3) {
        await axios
          .get(`${apiUrl}products/search?keywords=${value}&country=`, {
            headers: {
              Authorization: cookie.load("acccesstoken"),
            },
          })
          .then((res) => {
            if (res.data.status === "ok") {
              if (
                res.data.result_set.country.length > 0 ||
                res.data.result_set.product_list.length > 0
              ) {
                this.displaySearch(res.data.result_set);
              } else {
                this.setState({
                  displaySearchCountry: "",
                  displaySearchProducts: "",
                  showsearchList: false,
                });
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.setState({
          displaySearchCountry: "",
          displaySearchProducts: "",
          showsearchList: false,
        });
      }
      this.setState({ [name]: value, [name + "_error"]: "" });
    } catch (err) {
      console.log(err);
    }
  };
  displaySearch(result) {
    try {
      var displaySearchCountry = "";
      var displaySearchProducts = "";
      if (result.country.length > 0) {
        displaySearchCountry = result.country.map((item, index) => {
          var countryName = item.replace(" ", "-")?.toLowerCase();
          return (
            <li key={index}>
              <a
                href={void 0}
                onClick={() => this.selectProduct("country", item)}
              >
                <figure>
                  {" "}
                  <i className="fa fa-map-marker" aria-hidden="true"></i>{" "}
                </figure>
                <div className="search-show">
                  <p>{item}</p>
                </div>
              </a>
            </li>
          );
        });
      }
      if (result.product_list.length > 0) {
        displaySearchProducts = result.product_list.map((item, index) => {
          return (
            <li
              key={index}
              onClick={() => this.selectProduct("product", item)}
              id={"pro_" + item.products_id}
              className={
                item.product_total_package !== null &&
                parseInt(item.product_total_package) === 0
                  ? "not-available"
                  : ""
              }
            >
              <figure>
                {" "}
                <img
                  src={
                    item.product_thumbnail !== "" &&
                    item.product_thumbnail !== null
                      ? apiglobaltixMediaUrl + item.product_thumbnail
                      : pplaceholder
                  }
                  alt={item.products_name}
                />
              </figure>
              <div className="search-show active">
                <div className="search-show-inner">
                  <div className="search-show-product">
                    <p>{item.products_name}</p>
                    <span>{item.country_name}</span>
                  </div>
                  <div className="search-price">
                    <em>From</em>
                    <strong>SGD ${item.product_starting_price}</strong>
                  </div>
                </div>{" "}
              </div>
            </li>
          );
        });
      }
      this.setState({
        displaySearchCountry: displaySearchCountry,
        displaySearchProducts: displaySearchProducts,
        showsearchList: true,
      });
    } catch (err) {
      console.log(err);
    }
  }

  selectProduct = (type, item) => {
    try {
      var cityName =
        type === "product"
          ? item.country_name || item.country
          : item.city_name?.toLowerCase();
      var countryName =
        type === "product" ? item.country_name : item.country_name;
      var bookingdetails = {};
      bookingdetails["activitiyIndex"] = "";
      bookingdetails["dayIndex"] = 1;
      bookingdetails["activities"] = [
        {
          day: 1,
          date: api_start_date,
          emptyactivity: "Yes",
          activities: [],
        },
      ];
      bookingdetails["selectedDay"] = "";
      bookingdetails["ttdCity"] = cityName;
      bookingdetails["product_name"] = item?.product_slug || "";
      bookingdetails["searchdata"] = {
        destinationCity: item?.city,
        total_activities: "",
        ttdOnly: true,
      };
      if (type === "product") {
        this.props.history.push({
          pathname: `/search-package/create-own-package/all-activities/${countryName}/${item.product_slug}`,
          state: bookingdetails,
        });
      } else {
        this.props.history.push({
          pathname: `/search-package/create-own-package/all-activities/${countryName}`,
          state: bookingdetails,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
  loadAirport(searchtype, countryname) {
    axios
      .get(apiUrl + "settings/getAirport?country=" + countryname, {
        headers: {
          Authorization: cookie.load("acccesstoken"),
        },
      })
      .then((res) => {
        var airport = [];
        if (res.data.status === "ok") {
          res.data.result_set.map((item) => {
            airport.push({
              value:
                item.airport_code +
                "_" +
                item.airport_id +
                "_" +
                item.airport_address +
                "_" +
                item.airport_latitude +
                "_" +
                item.airport_longitude +
                "_" +
                item.airport_city_name,
              label: item.airport_name + " - " + item.airport_code,
            });
          });
          if (searchtype === "source") {
            this.setState(
              {
                airport: airport,
                searchairport: airport,
                loading: false,
              },
              function () {
                if ($(".mCustomScrollbar").length > 0) {
                  $(".mCustomScrollbar").mCustomScrollbar();
                }
              }
            );
          } else {
            this.setState({
              destination_airport: airport,
            });
          }
        }
      });
  }

  getAllairPorts(searchtype) {
    var airport = [];
    this.state.airportsData?.result_set?.map((item) => {
      airport.push({
        value:
          item.airport_code +
          "_" +
          item.airport_id +
          "_" +
          item.airport_address +
          "_" +
          item.airport_latitude +
          "_" +
          item.airport_longitude +
          "_" +
          item.airport_city_name,
        label:
          item.airport_name +
          " - " +
          item.airport_code /* + " - " + item.airport_city_name + " - " + item.airport_country */,
        country: item.airport_country,
        city: item.airport_city_name,
      });
    });
    if (searchtype === "source") {
      this.setState({
        airport: airport,
        searchairport: [],
        loading: false,
      });
    } else {
      this.setState({
        destination_airport: airport,
      });
    }
  }

  onChange(name, value) {
    if (name === "selectedairport") {
      var splitvalue = value.value.split("_");
      this.setState(
        {
          location_source: value.label,
          source_packagecountry: value.country,
          source_airport_code: splitvalue[0],
          source_airport_id: splitvalue[1],
          source_airport_address: splitvalue[2],
          source_airport_lat: splitvalue[3],
          source_airport_lon: splitvalue[4],
          source_packageCity: splitvalue[5],
          searchairportkeywords: value.label,
        },
        () => {
          if (this.state.packageType === "uop") {
            this.searchPackage();
          } else {
            this.setState({ step: 6 });
          }
        }
      );
    } else if (name === "destination_selectedairport") {
      var splitvalue = value.value.split("_");
      this.setState({
        location_destination: value.label,
        destination_airport_code: splitvalue[0],
        destination_airport_id: splitvalue[1],
        destination_airport_address: splitvalue[2],
        destination_airport_lat: splitvalue[3],
        destination_airport_lon: splitvalue[4],
      });
    }
    this.setState({ [name]: value }, function () {
      if (name === "search_days") {
        this.setState({ step: 6, searchedDays: value });
      }
    });
  }
  handleTextChange(type, event) {
    if (type === "city") {
      this.setState(
        { searchkeywords: event.target.value, selectedDestinationdetails: "" },
        function () {
          this.filterSearchCity();
        }
      );
    } else if (type === "country") {
      this.setState(
        { searchcountrykeywords: event.target.value, selectcountry: false },
        function () {
          this.filterSearchCountry();
        }
      );
    } else if (type === "airport") {
      this.setState(
        { searchairportkeywords: event.target.value },
        function () {
          this.filterSearchAirport();
        },
        function () {
          if ($(".mCustomScrollbar").length > 0) {
            $(".mCustomScrollbar").mCustomScrollbar();
          }
        }
      );
    }
  }
  filterSearchCity() {
    var searchCityList = [];
    var searchCountryList = [];
    var cityList = this.state.cityList;
    if (Object.keys(cityList).length > 0) {
      Object.keys(cityList).map((item) => {
        var selectedCountry = cityList[item];
        if (selectedCountry.city.length > 0) {
          selectedCountry.city.map((cityItem) => {
            /*      console.log(cityItem, "cityItemcityItemcityItem"); */
            if (
              cityItem.city_name
                ?.toLowerCase()
                .indexOf(this.state.searchkeywords?.toLowerCase()) >= 0 ||
              cityItem.country_name
                ?.toLowerCase()
                .indexOf(this.state.searchkeywords?.toLowerCase()) >= 0 ||
              this.state.searchkeywords === ""
            ) {
              if (searchCountryList.indexOf(cityItem.country_name) < 0) {
                searchCountryList.push(cityItem.country_name);
              }
              /* if (this.state.searchkeywords !== "") { */
              searchCityList.push({
                airport_address: cityItem.airport_address,
                airport_code: cityItem.airport_code,
                airport_id: cityItem.airport_id,
                airport_latitude: cityItem.airport_latitude,
                airport_longitude: cityItem.airport_longitude,
                airport_name: cityItem.airport_name,
                city_code: cityItem.city_code,
                city_id: cityItem.city_id,
                city_name: cityItem.city_name,
                country_code: cityItem.country_code,
                country_name: cityItem.country_name,
                images: cityItem.images,
                thumbnail: cityItem.thumbnail,
                webbeds_city_code: cityItem.webbeds_city_code,
              });
              /*  } */
            }
          });
        }
      });
    }
    this.setState(
      { searchCityList: searchCityList, destinationList: searchCountryList },
      function () {
        if ($(".mCustomScrollbar").length > 0) {
          $(".mCustomScrollbar").mCustomScrollbar();
        }
      }
    );
  }
  filterSearchCountry() {
    var searchCountryList = [];
    var countryList = this.state.countryList;
    if (countryList.length > 0) {
      countryList.map((countryItem) => {
        var checking_Destination = 1;
        if (this.state.selectedDestinationdetails !== "") {
          if (
            this.state.selectedDestinationdetails.country_name ===
            countryItem.country_name
          ) {
            checking_Destination = 0;
          }
        }

        if (
          countryItem.country_name
            ?.toLowerCase()
            .indexOf(this.state.searchcountrykeywords?.toLowerCase()) >= 0 &&
          checking_Destination === 1
        ) {
          searchCountryList.push(countryItem);
        }
      });
    }
    this.setState({ searchCountryList: searchCountryList }, function () {
      if ($(".mCustomScrollbar").length > 0) {
        $(".mCustomScrollbar").mCustomScrollbar();
      }
    });
  }
  filterSearchAirport() {
    var searchairport = [];
    var airportList = this.state.airport;
    if (
      airportList.length > 0 &&
      this.state.searchairportkeywords !== "" &&
      this.state.searchairportkeywords.length > 2
    ) {
      airportList.map((airportItem) => {
        if (
          airportItem.value
            ?.toLowerCase()
            .indexOf(this.state.searchairportkeywords?.toLowerCase()) >= 0
        ) {
          searchairport.push(airportItem);
        }
      });
      console.log(searchairport, "searchairport");
      this.setState({ searchairport }, function () {
        if ($(".mCustomScrollbar").length > 0) {
          $(".mCustomScrollbar").mCustomScrollbar();
        }
      });
    }
  }
  gotoNextStep(step) {
    console.log("step number", step);
    /*     if (step === 4) { */
    var timeDifference = this.state.end_date - this.state.start_date;
    var daysDifference = timeDifference / (1000 * 60 * 60 * 24);
    daysDifference = Math.round(daysDifference) + 1;
    console.log(daysDifference, "count");
    let selectedOption = "";
    if (/* daysDifference >= 2 &&  */ daysDifference <= 5) {
      selectedOption = "2-5";
    } else if (daysDifference >= 6 && daysDifference <= 8) {
      selectedOption = "6-8";
    } else if (daysDifference >= 9 && daysDifference <= 12) {
      selectedOption = "9-12";
    } else if (daysDifference >= 13 && daysDifference <= 15) {
      selectedOption = "13-15";
    }
    this.setState({
      search_days: selectedOption,
      days_count: daysDifference,
    });
    /*     } */
    this.setState({ step: step }, function () {
      if (step === 2) {
        $("#search_destination").focus();
        if ($(".mCustomScrollbar").length > 0) {
          $(".mCustomScrollbar").mCustomScrollbar();
        }
      }
    });
  }
  selectNewDestination(Destinationdetails, step) {
    this.setState({
      selectedDestinationdetails: Destinationdetails,
      opendestinationList: false,
      selectedDestination: true,
      error_location_source: false,
      /*  step: step, */
    });
  }
  handleSelectDate(ranges) {
    const range = ranges.selection || ranges.range1;
    console.log(range, "range");
    this.setState(
      {
        dateRange: {
          ...ranges.selection,
          startDate: ranges, // Keep existing startDate or set to today if undefined
          endDate: ranges,
        },
        start_date: ranges,
        openFromDateRange: this.state.trip_type === "Return" ? false : true,
        openToDateRange: this.state.trip_type === "Return" ? true : false,
      },
      function () {}
    );
  }
  handleSelectDateRange(ranges) {
    const range = ranges.selection || ranges.range1;
    this.setState(
      {
        dateRange: {
          ...range.range1,
          startDate: this.state.start_date,
          endDate: range.endDate,
        },
        end_date: range?.endDate,
      },
      function () {}
    );
  }
  handleSelectDateReturn(ranges) {
    console.log(ranges, "ranged");
    const range = ranges.selection || ranges.range1;
    const startDate = range.startDate;
    let endDate = this.state.end_date;

    // Check if startDate is greater than endDate
    if (startDate > endDate) {
      // Set endDate to two days after startDate
      const adjustedEndDate = new Date(startDate);
      adjustedEndDate.setDate(adjustedEndDate.getDate() + 2);
      endDate = adjustedEndDate;
    }

    this.setState({
      dateRange: {
        ...range.range1,
        endDate: endDate,
        startDate: startDate,
      },
      start_date: startDate,
      end_date: endDate,
      fromRange: false,
      openToDateRange: true,
    });
  }
  handleSelectDateRangeSelectRange(ranges) {
    this.setState(
      {
        selectionRange: ranges?.selection,
        start_date: ranges?.selection?.startDate,
        end_date: ranges?.selection?.endDate,
      },
      function () {
        if (
          ranges?.selection?.startDate !== "" &&
          ranges?.selection?.endDate !== ""
        ) {
          if (ranges?.selection?.startDate !== ranges?.selection?.endDate) {
            setTimeout(() => {
              this.setState({ showDatePopup: false, error_start_date: false });
            }, 1000);
          }
        }
      }
    );
  }
  loadCountry() {
    if (this.state.getCountry.status === "ok") {
      var countryList = this.state.getCountry?.result_set;
      var highlightcountry = this.state.getCountry?.highlightcountry;
      this.setState({
        countryList: countryList,
        highlightcountry: highlightcountry,
      });
    }
  }
  loadCity = async (clickCliy = "N", countryName = "", cityName = "") => {
    if (this.state.getCityList?.status === "ok") {
      this.setState(
        {
          city_List_carousel: this.state.getCityList.result_set,
        },
        function () {
          this.filterSearchCity();
          if (clickCliy === "Y") {
            this.selectCountryPackages(countryName, cityName);
          }
        }
      );
    }
  };

  handleSearchFromHeader = (param) => {
    if (param === "header") {
      this.setState({ mobileStep: 1, openBottomSheet: true });
    } else {
      this.setState({
        mobileStep: 2,
        openBottomSheet: true,
      });
    }
  };

  async handleProduct(item) {
    var leadEmail = localStorage.getItem("leadEmail") || "";
    if (leadEmail !== "") {
      this.setState({ selectedItem: item, selectAutoPackageLeadPopup: true });
    } else {
      this.setState({
        selectedItem: item,
        showPackageLeadPopup: true,
      });
    }
  }

  continuePackage = (item) => {
    var selected_City = "";
    Object.values(this.state.city_List_carousel).forEach((country) => {
      var countryDetails = Object.values(country);
      countryDetails[1].map((city) => {
        if (
          city.city_name?.toLowerCase() ===
          item.creator_package_city?.toLowerCase()
        ) {
          selected_City = city;
        }
      });
    });
    try {
      var homePackFlow = false;
      if (item !== "") {
        homePackFlow = true;
      }
      var startdate =
        this.state.start_date !== "" ? this.state.start_date : start_date;

      var searchdata = {
        start_date: startdate,
        end_date: this.state.end_date !== "" ? this.state.end_date : end_date,
        selectedMonth: this.state.selectedMonth,
        search_days: "2-5",
        adults: 1,
        child: this.state.child,
        infant: this.state.infant,
        room_count: 1,
        source_airport_code: "BLR",
        location_source:
          "Kempegowda International Airport Bengaluru (BLR), KIAL Rd, Devanahalli, Bengaluru, Karnataka 560300, India",
        source_airport_id: "2800",
        destinationWebbedsCity: selected_City.webbeds_city_code,
        creator_package_total_days: item.creator_package_total_days,
        source_airport_address: "BLR",
        source_airport_lat: "12.994112",
        source_airport_lon: "80.1708668",
        destination_airport_lat: selected_City.airport_latitude,
        destination_airport_lon: selected_City.airport_longitude,
        destination_airport_address: selected_City.airport_address,
        destinationCity: selected_City.city_name,
        destinationCountry: selected_City.country_name,
        destination_airport_code: selected_City.airport_code,
        destination_airport_id: selected_City.airport_id,
        location_destination: item.creator_package_location,
        thumbnail: item.thumbnail,
        images: item.images,
        childAgeList: this.state.childAgeList,
        trip_type: "Return",
        transfer_trip_type: "Return",
        sourceCountry: "India",
        sourceCity: "",
        checkinTime: this.state.checkinTime,
        ttdOnly: this.state.ttdOnly,
        activityList: item.activities !== undefined ? item.activities : "",
        total_activities:
          item.total_activities !== undefined ? item.total_activities : 0,
        homePackFlow: homePackFlow,
        packageType: item?.creator_package_source || "uop",
      };

      var endDate = new Date(startdate);
      var no_of_days =
        this.state.ttdOnly === false
          ? item.creator_package_total_days
          : item.creator_package_total_days - 1;
      searchdata["end_date"] = new Date(
        endDate.setDate(parseInt(endDate.getDate() + parseInt(no_of_days)))
      );
      searchdata["checkinTime"] =
        item.hotel_check_in_time !== "" &&
        typeof item.hotel_check_in_time !== undefined &&
        typeof item.hotel_check_in_time !== "undefined"
          ? item.hotel_check_in_time
          : "";
      console.log(searchdata, "searchdata");
      var slug =
        this.state.siteUrl !== ""
          ? this.state.siteUrl
          : `/search-package/${item.creator_package_slug}`;
      this.props.history.push({
        pathname: slug,
        state: {
          searchdata: searchdata,
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  openPopup(popupID) {
    $.magnificPopup.open({
      items: {
        src: popupID,
      },
      type: "inline",
      closeOnBgClick: false,
      midClick: true,
      mainClass: this.state.isMobile ? "package-info" : "",
      showCloseBtn: true,
      callbacks: {
        open: function () {
          $("body").css("overflow", "hidden");
        },
        close: function () {
          $("body").css("overflow", "");
          console.log("csksdk");
          if (popupID === "#calendar-date-popup") {
            this.openPopup("#search-popup");
          }
        },
      },
    });
    $.magnificPopup.instance.close = () => {
      $.magnificPopup.proto.close.call(this);
      if (popupID === "#calendar-date-popup") {
        this.openPopup("#search-popup");
      }
    };
  }
  handleChange(field, e) {
    this.setState({ [field]: e.target.value, [field + "Error"]: "" });
  }
  showhidePWD() {
    if (this.state.passwordType === "password") {
      this.setState({ passwordType: "text" });
    } else {
      this.setState({ passwordType: "password" });
    }
  }
  showhideCPWD() {
    if (this.state.cpasswordType === "password") {
      this.setState({ cpasswordType: "text" });
    } else {
      this.setState({ cpasswordType: "password" });
    }
  }
  resetpasswordSubmit(e) {
    e.preventDefault();
    var error = 0;
    $("#form-msg").html("");
    if (this.state.new_password === "") {
      error++;
      this.setState({ new_passwordError: "This field is required" });
    } else {
      if (this.state.new_password.length < 8) {
        this.setState({
          new_passwordError: "Password must be at least 8 characters.",
        });
      } else {
        this.setState({ new_passwordError: "" });
      }
    }
    if (this.state.confirm_password === "") {
      error++;
      this.setState({ confirm_passwordError: "This field is required" });
    } else {
      if (this.state.new_password !== this.state.confirm_password) {
        error++;
        this.setState({
          confirm_passwordError: "Confirm Password does not match",
        });
      } else {
        this.setState({ confirm_passwordError: "" });
      }
    }
    if (error === 0) {
      var postObject = {
        key: this.state.resetKey,
        password: base64.encode(this.state.new_password),
        confirmpassword: base64.encode(this.state.confirm_password),
        type: "Password",
      };
      $(".spn-error").html("");
      var postURL = "";
      var urlPath = this.props.match.path;
      if (urlPath === "/creatoraccount/resetpassword/:resetKey") {
        postURL = "creators/resetpassword";
      } else if (urlPath === "/account/resetpassword/:resetKey") {
        postURL = "customer/resetpassword";
      }
      if (postURL !== "") {
        showLoader("reset-password");
        axios
          .post(apiUrl + postURL, qs.stringify(postObject), {
            headers: {
              Authorization: cookie.load("acccesstoken"),
            },
          })
          .then((res) => {
            hideLoader("reset-password");
            if (res.data.status === "ok") {
              showAlert("Success", res.data.message);
              $.magnificPopup.open({
                items: {
                  src: ".alert_popup",
                },
                type: "inline",
              });
              if (urlPath === "/creatoraccount/resetpassword/:resetKey") {
                this.props.history.push("/creator");
              } else if (urlPath === "/account/resetpassword/:resetKey") {
                this.props.history.push("/");
              }
            } else {
              if (res.data.form_error) {
                document.getElementById("form-msg").innerHTML =
                  '<span class="error">' + res.data.form_error + "</span>";
              } else {
                document.getElementById("form-msg").innerHTML =
                  '<span class="error">' + res.data.message + "</span>";
              }
            }
          });
      }
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const { subMail, subvalid, emailvalid } = this.state;
    const data = {
      email: subMail,
    };

    if (emailvalid === false && this.state.subMail !== "") {
      axios
        .post(subApiurl + "subscribe", data, {
          headers: {
            Authorization: cookie.load("acccesstoken"),
          },
        })
        .then((response) => {
          if (response.data.status === "ok") {
            this.setState(
              {
                submsg: response.data.message,
                subMail: "",
                Errorpop: true,
              },
              function () {
                $.magnificPopup.open({
                  items: {
                    src: "#earning-details",
                  },
                  type: "inline",
                  showCloseBtn: false,
                  midClick: true,
                  closeOnBgClick: false,
                });
              }
            );
          } else if (response.data.status === "error") {
            this.setState(
              {
                submsg: response.data.error,
                Errorpop: true,
                subMail: "",
              },
              function () {
                $.magnificPopup.open({
                  items: {
                    src: "#earning-details",
                  },
                  type: "inline",
                  showCloseBtn: false,
                  midClick: true,
                  closeOnBgClick: false,
                });
              }
            );
          }
        });
    }
  };

  handleNameChange = (event) => {
    this.setState({ subMail: event.target.value });
    if (event.target.value === "") {
      this.setState({ subvalid: true });
    } else {
      this.setState({ subvalid: false });
    }
    if (
      !/^\S+@\S+\.\S+$/.test(event.target.value) &&
      event.target.value !== ""
    ) {
      this.setState({ emailvalid: true });
    } else {
      this.setState({ emailvalid: false });
    }
  };
  closePopup() {
    $.magnificPopup.close();
    this.setState({
      Errorpop: false,
      submsg: "",
    });
  }

  closeDatePopup() {
    if (this.state.isMobile) {
      this.setState({ openCalendarBottomSheet: false });
    } else {
      $.magnificPopup.close();
      this.openPopup("#search-popup");
    }
  }

  setSwapRoute() {
    this.setState({
      source_airport_code: this.state.destination_airport_code,
      fromCityInput: this.state.toCityInput,
      sourceCityItem: this.state.desCityItem,
      destination_airport_code: this.state.source_airport_code,
      toCityInput: this.state.fromCityInput,
      desCityItem: this.state.sourceCityItem,
    });
  }

  handleSearchFlight = (item) => {
    try {
      var bookingdetails = {
        searchdata: {
          adults: 1,
          child: 0,
          childAgeList: [],
          infant: 0,
          start_date: moment().add(7, "days").format(),
          source_airport_code: item?.from_to?.split("-")[0],
          destination_airport_code: item?.from_to?.split("-")[1],
          trip_type: item?.trip_type,
          location_source: item?.from_airport,
          location_destination: item?.to_airport,
        },
        flightOnly: true,
        packagedetails: [],
        flightList: [],
        selectedflight: "",
        flightPrice: [],
        addFlight: "",
        removeFlight: "",
        addnewflight: "Yes",
      };
      this.props.history.push({
        pathname: "/search-package/create-own-package/flight",
        state: bookingdetails,
      });
    } catch (err) {
      console.log(err);
    }
  };

  searchNewFlight = () => {
    let error = 0;
    let source_airport_error = "";
    let destination_airport_error = "";
    let fromdate_airport_error = "";
    let todate_airport_error = "";
    if (this.state.start_date === "") {
      error++;
      fromdate_airport_error = "Please select Departure date first";
    }
    if (this.state.end_date === "" && this.state.trip_type === "Return") {
      error++;
      todate_airport_error = "Please select Return date";
    }
    if (this.state.source_airport_code === "") {
      error++;
      source_airport_error = "Please select valid Source";
    }
    if (this.state.destination_airport_code === "") {
      error++;
      destination_airport_error = "Please select valid Destination";
    }
    if (error === 0) {
      try {
        var bookingdetails = {
          searchdata: {
            adults: this.state.adults,
            child: this.state.child,
            infant: this.state.infant,
            childAgeList: this.state.childAgeList,
            start_date: this.state.start_date,
            end_date: this.state.end_date,
            source_airport_code: this.state.source_airport_code,
            destination_airport_code: this.state.destination_airport_code,
            trip_type: this.state.trip_type,
            location_source: this.state.sourceCityItem.airport_name,
            location_destination: this.state.desCityItem.airport_name,
            sourceCity: this.state.fromCityInput,
            destinationCity: this.state.toCityInput,
            selectedMonth: this.state.selectedMonth,
          },
          flightOnly: true,
          packagedetails: [],
          flightList: [],
          selectedflight: "",
          flightPrice: [],
          addFlight: "",
          removeFlight: "",
          addnewflight: "Yes",
        };
        if (this.state.start_date !== "") {
          this.props.history.push({
            pathname: "/search-package/create-own-package/flight",
            state: bookingdetails,
          });
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      this.setState({
        source_airport_error,
        destination_airport_error,
        fromdate_airport_error,
        todate_airport_error,
      });
    }
  };
  handleCityChange = (e, title) => {
    console.log(e.target.value);
    try {
      const value = e.target.value;
      this.setState(
        {
          [title]: value,
          [title === "fromCityInput"
            ? "source_airport_code"
            : "destination_airport_code"]: "",
          ...(title === "toCityInput" && { destination_airport_error: "" }),
          ...(title === "fromCityInput" && { source_airport_error: "" }),
        },
        () => {
          if (value.length >= 3) {
            this.fetchCities(title);
          }
        }
      );
    } catch (err) {
      console.log(err);
    }
  };

  handleMouseEnter = (action) => {
    try {
      console.log(action);
      this.setState({ isHovered: action });
    } catch (err) {
      console.log(err);
    }
  };
  handleRadioChange = (ev) => {
    this.setState({ trip_type: ev.target.value });
  };
  handleClickOutside = (event) => {
    if (
      this.cityFromRef.current &&
      !this.cityFromRef.current.contains(event.target)
    ) {
      if (this.state.isHovered == "CITY_FROM") {
        this.setState({ isHovered: null });
      }
    }

    if (
      this.cityToRef.current &&
      !this.cityToRef.current.contains(event.target)
    ) {
      if (this.state.isHovered == "CITY_TO") {
        this.setState({ isHovered: null });
      }
    }

    if (
      this.daterangeRef.current &&
      !this.daterangeRef.current.contains(event.target)
    ) {
      if (this.state.isHovered == "DATE_RANGE") {
        this.setState({ isHovered: null });
      }
    }
    if (
      this.openFromDateRangeRef.current &&
      !this.openFromDateRangeRef.current.contains(event.target)
    ) {
      if (this.state.openFromDateRange)
        this.setState({ openFromDateRange: false, fromRange: false });
    }
    if (
      this.openToDateRangeRef.current &&
      !this.openToDateRangeRef.current.contains(event.target)
    ) {
      if (this.state.openToDateRange)
        this.setState({ openToDateRange: false, fromRange: false });
    }
    if (
      this.passengerListRef.current &&
      !this.passengerListRef.current.contains(event.target)
    ) {
      if (this.state.isHovered == "PASSENGER_LIST") {
        this.setState({ isHovered: null });
      }
    }
  };

  passengerSelection = () => {
    return (
      <div className="passenger-body">
        <ul>
          <li>
            <div className="addcart_row">
              <label className="sel-quality">
                Adult
                <span className="price-select">Age 13 or above</span>
              </label>
              <div className="qty-bx">
                <span
                  className="qty-minus"
                  onClick={this.applyQty.bind(this, "adults", "M")}
                >
                  <img className="minus-ic" src={minusb} alt="Minus" />
                </span>
                <input
                  type="text"
                  className="qty-input"
                  readOnly={true}
                  value={this.state.adults}
                />
                <span
                  className="qty-plus"
                  onClick={this.applyQty.bind(this, "adults", "P")}
                >
                  <img className="plus-ic" src={plusb} alt="Plus" />
                </span>
              </div>
            </div>
          </li>
          <li className={this.state.adults === 0 ? "disable" : ""}>
            <div className="addcart_row">
              <label className="sel-quality">
                Children
                <span className="price-select">Age 2 to 12</span>
              </label>
              <div className="qty-bx">
                <span
                  className="qty-minus"
                  onClick={this.applyQty.bind(this, "child", "M")}
                >
                  <img className="minus-ic" src={minusb} alt="Minus" />
                </span>
                <input
                  type="text"
                  className="qty-input"
                  readOnly={true}
                  value={this.state.child}
                />
                <span
                  className="qty-plus"
                  onClick={this.applyQty.bind(this, "child", "P")}
                >
                  <img className="plus-ic" src={plusb} alt="Plus" />
                </span>
              </div>
            </div>
            {/* {this.state.child > 0 && (
              <div className="child-age-select">
                <div className="age-sel">
                  {(() => {
                    const options = [];
                    for (let i = 0; i < this.state.child; i++) {
                      options.push(
                        <div key={i}>
                          <label>
                            Select age for child {parseInt(i) + 1}{" "}
                            <span>Mandatory for booking</span>
                          </label>
                          <Select
                            placeholder="Select age"
                            options={childAgeOptions}
                            onChange={this.onChangeChildAge.bind(this, i)}
                            value={
                              this.state.childAgeList[i] !== "" &&
                              typeof this.state.childAgeList[i] !== undefined &&
                              typeof this.state.childAgeList[i] !== "undefined"
                                ? this.state.childAgeList[i]
                                : []
                            }
                          />
                        </div>
                      );
                    }
                    return options;
                  })()}
                </div>
              </div>
            )} */}
          </li>
          <li className={this.state.adults === 0 ? "disable" : ""}>
            <div className="addcart_row">
              <label className="sel-quality">
                Infants
                <span className="price-select">Under 2</span>
              </label>
              <div className="qty-bx">
                <span
                  className="qty-minus"
                  onClick={this.applyQty.bind(this, "infant", "M")}
                >
                  <img className="minus-ic" src={minusb} alt="Minus" />
                </span>
                <input
                  type="text"
                  className="qty-input"
                  readOnly={true}
                  value={this.state.infant}
                />
                <span
                  className="qty-plus"
                  onClick={this.applyQty.bind(this, "infant", "P")}
                >
                  <img className="plus-ic" src={plusb} alt="Plus" />
                </span>
              </div>
            </div>
          </li>
        </ul>
      </div>
    );
  };
  displayTtdPackages() {
    const ttdPackages = this.state.ttdPackages;
    try {
      var display_ttd_package = {};
      if (Object.keys(this.state.ttdPackages)?.length) {
        Object.keys(this.state.ttdPackages)?.map((key, index_pac) => {
          if (typeof this.state.ttdPackages[key] !== "string") {
            if (key !== "Top 10") {
              const segments = [];
              this.state?.ttdPackages[key]?.map((segment, i) => {
                const carouselRef = React.createRef();
                const segment_packages = segment?.packages?.map(
                  (item, index) => {
                    // const segment_packages = segment?.packages?.map((item, index) => {
                    var discount = null;
                    // discount = checkingDiscount(
                    //   item?.discount,
                    //   item?.price
                    // );
                    var displyAmount = item?.price;
                    if (discount !== "" && discount !== null) {
                      displyAmount =
                        parseFloat(item?.price) - parseFloat(discount?.amount);
                    }
                    return (
                      <div key={index}>
                        <ul className="package-ul">
                          <li>
                            <div className="product-list-parent">
                              <div className="product-img">
                                <a
                                  className="pt-img"
                                  href={void 0}
                                  onClick={() =>
                                    this.selectProduct("product", item)
                                  }
                                >
                                  <img
                                    src={apiglobaltixMediaUrl + item?.thumbnail}
                                    alt={"thumbnail"}
                                    loading="lazy"
                                  />
                                </a>
                              </div>
                              <div className="product-description">
                                <div className="product-description-title">
                                  <div className="title-col">
                                    <h5>
                                      <a
                                        href={void 0}
                                        onClick={() =>
                                          this.selectProduct("product", item)
                                        }
                                      >
                                        {item?.product_name}
                                      </a>{" "}
                                    </h5>
                                    <span className="cityname">
                                      <img src={locationIcon} />
                                      {item.city}
                                    </span>
                                  </div>
                                </div>
                                <div className="footer-main">
                                  <div className="product-description-review">
                                    <div className="pdr-lhs">
                                      {item?.product_rating !== "" &&
                                        item?.product_rating !== null && (
                                          <div className="review-col pdr-lhs">
                                            <span>
                                              <strong>
                                                {item?.product_rating}
                                              </strong>{" "}
                                              ({item?.product_total_review})
                                            </span>
                                            <div className="star-rating">
                                              {this.loadRatingstar(
                                                item?.product_rating
                                              )}
                                            </div>
                                          </div>
                                        )}
                                    </div>
                                    <div className={`pdr-rhs`}>
                                      {discount !== "" && discount !== null ? (
                                        <>
                                          <span className="package-original-amt">
                                            {showPriceValue(
                                              item?.price,
                                              this.state.currentCurrency,
                                              this.state.currencyRate
                                            )}
                                          </span>
                                        </>
                                      ) : (
                                        <em>Starts from</em>
                                      )}
                                      <strong>
                                        {showPriceValue(
                                          displyAmount,
                                          this.state.currentCurrency,
                                          this.state.currencyRate
                                        )}
                                      </strong>{" "}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    );
                    // });
                  }
                );
                segments.push({
                  segmentName: segment.segment_name,
                  carouselRef: carouselRef,
                  packages: segment_packages,
                  isLoading: false,
                });
              });
              display_ttd_package[key] = segments;
            } else {
              const temp = [];
              const top10 = ttdPackages[key];
              Object.keys(top10)?.forEach((key1) => {
                const carouselRef = React.createRef();
                temp.push({
                  segmentName: key1,
                  carouselRef: carouselRef,
                  packages: top10[key1],
                  isLoading: false,
                });
              });
              display_ttd_package[key] = temp;
            }
          } else {
            display_ttd_package[key] = ttdPackages[key];
          }
        });
      }
      this.setState({
        display_ttd_package: display_ttd_package,
      });
    } catch (err) {
      console.log(err);
    }
  }
  displayPackages() {
    try {
      var display_home_package = {};
      if (
        this.state?.homePackages &&
        Object.keys(this.state?.homePackages)?.length
      ) {
        Object.keys(this.state?.homePackages)?.map((key, index_pac) => {
          const segments = [];
          this.state?.homePackages[key]?.map((segment, i) => {
            const carouselRef = React.createRef();
            const segment_packages = segment?.packages?.map((item, index) => {
              var discount = null;
              discount = checkingDiscount(
                item?.discount,
                item?.creator_package_price
              );
              var displyAmount = item?.creator_package_price;
              if (discount !== "" && discount !== null) {
                displyAmount =
                  parseFloat(item?.creator_package_price) -
                  parseFloat(discount?.amount);
              }
              return (
                <div key={index}>
                  <ul className="package-ul">
                    <li>
                      <div className="product-list-parent">
                        <div className="product-img">
                          <a href={void 0} className="like">
                            {item?.wishlist === "yes" ? (
                              <img src={heartfill} alt="Filled Heart" />
                            ) : (
                              <img src={heart} alt="Empty Heart" />
                            )}
                          </a>
                          <a
                            className="pt-img"
                            href={void 0}
                            onClick={() => this.handleProduct(item)}
                          >
                            <img
                              src={
                                this.state?.isMobile
                                  ? item?.creator_package_thumbnail_mobile !==
                                      "" &&
                                    item?.creator_package_thumbnail_mobile !==
                                      null
                                    ? item?.creator_package_thumbnail_mobile
                                    : actinfo1
                                  : item?.creator_package_thumbnail_web !==
                                      "" &&
                                    item?.creator_package_thumbnail_web !== null
                                  ? item?.creator_package_thumbnail_web
                                  : actinfo1
                              }
                              alt={item?.creator_image}
                              loading="lazy"
                            />
                          </a>
                          <div className="ptd-day">
                            {" "}
                            {item?.creator_package_total_days} Days
                          </div>
                          {discount !== "" && (
                            <span className="package-discount">
                              {showPriceValue(
                                discount?.amount,
                                this.state.currentCurrency,
                                this.state.currencyRate
                              )}{" "}
                              Off
                            </span>
                          )}
                        </div>
                        <div className="product-description">
                          {/* <div className="tags-row">
                            <div className="new-tags">
                              <div className="c-tags">
                                {item?.creator_package_travelling}
                              </div>
                              <div className="e-tags">{item?.category_title}</div>
                            </div>
                            <div className="days-col">
                              {item?.creator_package_total_days} Days,{" "}
                              {item?.creator_package_total_days - 1} Nights
                            </div>
                          </div> */}
                          <div className="product-description-title">
                            <div className="title-col">
                              <h5>
                                <a
                                  href={void 0}
                                  onClick={() => this.handleProduct(item)}
                                >
                                  {item?.creator_package_name}
                                </a>{" "}
                              </h5>
                            </div>
                            {/* {item?.creator_package_rating !== "" &&
                              item?.creator_package_rating !== null && (
                                <div className="review-col pdr-lhs">
                                  <span>
                                    <strong>
                                      {item?.creator_package_rating}
                                    </strong>{" "}
                                    ({item?.creator_package_total_rating}) Reviews
                                  </span>
  
                                  <div className="star-rating">
                                    {this.loadRatingstar(
                                      item?.creator_package_rating
                                    )}
                                  </div>
                                </div>
                              )} */}
                          </div>
                          <div className="product-created-content">
                            <div className="created-av">
                              <img
                                src={
                                  item?.creator_image !== "" &&
                                  item?.creator_image !== null
                                    ? item?.creator_image
                                    : avholder
                                }
                                alt="creator-logo"
                              />
                            </div>
                            <div className="created-av-title">
                              <h4>
                                <span>
                                  {item?.creator_package_source === "uop"
                                    ? "Operated by"
                                    : "Created by"}
                                </span>

                                {item?.creator_handle_name}
                              </h4>
                            </div>
                          </div>
                          {/* <div className="sites-content">
                            {item?.creator_package_source === "uop" ? (
                              <ul>
                                {item?.creator_package_included
                                  ?.split(",")
                                  .map((val, index) => (
                                    <li className="sc1" key={index}>
                                      <figure>
                                        <img src={flight} alt="flight" />
                                      </figure>
                                      <figcaption>{val}</figcaption>
                                    </li>
                                  ))}
                              </ul>
                            ) : (
                              <ul>
                                <li className="sc1">
                                  <figure>
                                    <img src={flight} alt="flight" />
                                  </figure>
                                  <figcaption>Flight (add on)</figcaption>
                                </li>
                                <li className="sc4">
                                  <figure>
                                    <img src={car} alt="car" />
                                  </figure>
                                  <figcaption>Transfer (add on)</figcaption>
                                </li>
                                {item?.stay?.length > 0 && (
                                  <li className="sc2">
                                    <figure>
                                      <img src={hotel} alt="hotel" />
                                    </figure>
                                    <figcaption>
                                      {item?.stay?.length} hotel options
                                    </figcaption>
                                  </li>
                                )}
  
                                {item?.total_activities > 0 && (
                                  <li className="sc3">
                                    <figure>
                                      <img src={ticketact} alt="ticketact" />
                                    </figure>
                                    <figcaption>
                                      {item?.total_activities} Things to do
                                    </figcaption>
                                  </li>
                                )}
                              </ul>
                            )}
                          </div> */}
                          {key !== "Customizable" ? (
                            <div className="footer-main">
                              <div className="product-description-review">
                                <div className="pdr-lhs">
                                  {item?.creator_package_rating !== "" &&
                                    item?.creator_package_rating !== null && (
                                      <div className="review-col pdr-lhs">
                                        <span>
                                          <strong>
                                            {item?.creator_package_rating}
                                          </strong>{" "}
                                          ({item?.creator_package_total_rating})
                                        </span>

                                        <div className="star-rating">
                                          {this.loadRatingstar(
                                            item?.creator_package_rating
                                          )}
                                        </div>
                                      </div>
                                    )}
                                </div>
                                <div
                                  className={`pdr-rhs ${
                                    item?.creator_package_source === "uop"
                                      ? ""
                                      : "invisible"
                                  }`}
                                >
                                  {discount !== "" && discount !== null ? (
                                    <>
                                      <span className="package-original-amt">
                                        {showPriceValue(
                                          item?.creator_package_price,
                                          this.state.currentCurrency,
                                          this.state.currencyRate
                                        )}
                                      </span>
                                    </>
                                  ) : (
                                    <em>Starts from</em>
                                  )}
                                  <strong>
                                    {showPriceValue(
                                      displyAmount,
                                      this.state.currentCurrency,
                                      this.state.currencyRate
                                    )}
                                  </strong>{" "}
                                </div>
                              </div>
                              {/* <div className="cp-main">
                              <Button
                                variant="contained"
                                onClick={() => this.continuePackage(item)}
                              >
                                {item?.creator_package_source === "uop"
                                  ? "Book now"
                                  : "Check price"}
                              </Button>
                            </div> */}
                            </div>
                          ) : (
                            <div className="cp-main">
                              <Button
                                variant="contained"
                                onClick={() => this.handleProduct(item)}
                              >
                                Customize your trip
                              </Button>
                            </div>
                          )}
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              );
            });
            segments.push({
              segmentName: segment.segment_name,
              carouselRef: carouselRef,
              packages: segment_packages,
              isLoading: false,
            });
          });
          display_home_package[key] = segments;
        });
      }
      this.setState(
        {
          display_home_package: display_home_package,
          homePackageLoading: false,
          showHomePackages: true,
        },
        () => {
          this.display_hyper();
        }
      );
      // setTimeout(() => {
      //   this.setState((prevState) => ({
      //     display_home_package: prevState.display_home_package.map(
      //       (segment) => ({
      //         ...segment,
      //         isLoading: false, // Set isLoading to false after data is fetched
      //       })
      //     ),
      //   }));
      // }, 2000);
    } catch (err) {
      console.log(err);
    }
  }

  selectCountryPackages(countryName, cityName) {
    var selected_City = "";
    Object.values(this.state.city_List_carousel).forEach((country) => {
      var countryDetails = Object.values(country);
      if (countryDetails[0] === countryName) {
        countryDetails[1].map((city) => {
          if (city.city_name === cityName) {
            selected_City = city;
          }
        });
      }
    });
    console.log(selected_City, "selected_City");
    var startdate =
      this.state.start_date !== "" ? this.state.start_date : start_date;
    var searchdata = {
      start_date: startdate,
      end_date: this.state.end_date !== "" ? this.state.end_date : end_date,
      selectedMonth: this.state.selectedMonth,
      search_days: "2-5",
      adults: 1,
      child: this.state.child,
      infant: this.state.infant,
      room_count: 1,
      source_airport_code: "BLR",
      location_source:
        "Kempegowda International Airport Bengaluru (BLR), KIAL Rd, Devanahalli, Bengaluru, Karnataka 560300, India",
      source_airport_id: "2800",
      destinationWebbedsCity: selected_City.webbeds_city_code,
      creator_package_total_days: 3,
      source_airport_address: "BLR",
      source_airport_lat: "12.994112",
      source_airport_lon: "80.1708668",
      destination_airport_lat: selected_City.airport_latitude,
      destination_airport_lon: selected_City.airport_longitude,
      destination_airport_address: selected_City.airport_address,
      destinationCity: selected_City?.city_name || cityName,
      destinationCountry: selected_City?.country_name || countryName,
      destination_airport_code: selected_City.airport_code,
      destination_airport_id: selected_City.airport_id,
      location_destination: selected_City.airport_address,
      thumbnail: "",
      images: "",
      childAgeList: this.state.childAgeList,
      trip_type: "Return",
      transfer_trip_type: "Return",
      sourceCountry: "India",
      sourceCity: "",
      checkinTime: this.state.checkinTime,
      ttdOnly: this.state.ttdOnly,
      activityList: "",
      total_activities: "",
      /*   homePackFlow: homePackFlow, */
      packageType: "uop",
      priceFilter: this.state.price,
    };
    this.props.history.push({
      pathname: `/packages/${countryName}/${cityName}`,
      state: searchdata,
    });
  }
  loadRatingstar(points) {
    if (points) {
      console.log(points, "points");
      const splitrating = points.split(".");
      const integerPart = parseInt(splitrating[0]);
      const decimalPart = parseFloat(`0.${splitrating[1] || 0}`);
  
      return [...Array(5)].map((star, index) => {
        const currentRate = index + 1;
  
        if (currentRate <= integerPart) {
          // Full star
          return <i className="fa fa-star" aria-hidden="true" key={index}></i>;
        } else if (currentRate === integerPart + 1 && decimalPart >= 0.5) {
          // Half star
          return (
            <i className="fa fa-star-half-o" aria-hidden="true" key={index}></i>
          );
        } else {
          // Empty star
          return (
            <i className="fa fa-star-o" aria-hidden="true" key={index}></i>
          );
        }
      });
    }
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("scroll", this.changeNavBg);
    document.removeEventListener("click", this.handleClickOutside);
  }
  handleFlightSearchSheet = (val) => {
    this.setState({ openFlightSearchSheet: val });
  };
  clearSearch() {
    this.setState(
      {
        selectedDestinationdetails: "",
        selectedDestination: false,
        searchCityKeyWord: "",
        error_location_source: false,
        packageType: "vacation",
        start_date: "",
        end_date: "",
        error_start_date: false,
        price: null,
      },
      () => {
        $("#search_city").val("");
      }
    );
  }
  continueExplore() {
    var error = 0;

    if (this.state.selectedDestinationdetails?.city_name) {
      this.setState({ error_location_source: false });
    } else {
      error++;
      this.setState({ error_location_source: true });
    }
    if (this.state.packageType === "vacation") {
      if (this.state.start_date === "") {
        error++;
        this.setState({ error_start_date: true });
      } else {
        if (this.state.start_date !== this.state.end_date) {
          this.setState({ error_start_date: false });
        } else {
          error++;
          this.setState({ error_start_date: true });
        }
      }
    } else if (this.state.packageType === "uop") {
      if (this.state.selectedMonth === "") {
        error++;
        this.setState({ error_month: true });
      } else {
        this.setState({ error_month: false });
      }
    }
    if (error === 0) {
      if (this.state.isMobile) {
        this.setState({ openSearchBottomSheet: false });
      } else {
        $.magnificPopup.close();
      }
      var timeDifference = this.state.end_date - this.state.start_date;
      var daysDifference = timeDifference / (1000 * 60 * 60 * 24);
      daysDifference = Math.round(daysDifference) + 1;
      let selectedOption = "";
      if (daysDifference <= 5) {
        selectedOption = "2-5";
      } else if (daysDifference >= 6 && daysDifference <= 8) {
        selectedOption = "6-8";
      } else if (daysDifference >= 9 && daysDifference <= 12) {
        selectedOption = "9-12";
      } else if (daysDifference >= 13 && daysDifference <= 15) {
        selectedOption = "13-15";
      }
      var start_date = this.state.start_date || new Date();
      var selectedMonth = moment(start_date).format("MMMM-YYYY");
      var packagedetails = {
        start_date: start_date,
        end_date: this.state.end_date || new Date(),
        selectedMonth: selectedMonth,
        search_days: selectedOption,
        adults: this.state.adults,
        child: this.state.child,
        infant: this.state.infant,
        room_count: this.state.room_count,
        source_airport_code: this.state.source_airport_code,
        location_source: this.state.source_airport_address,
        source_airport_id: this.state.source_airport_id,
        source_airport_address: this.state.source_airport_code,
        source_airport_lat: this.state.source_airport_lat,
        source_airport_lon: this.state.source_airport_lon,
        destination_airport_code:
          this.state.selectedDestinationdetails.airport_code,
        location_destination:
          this.state.selectedDestinationdetails.airport_address,
        destination_airport_id:
          this.state.selectedDestinationdetails.airport_id,
        destination_airport_address:
          this.state.selectedDestinationdetails.airport_address,
        destination_airport_lat:
          this.state.selectedDestinationdetails.airport_latitude,
        destination_airport_lon:
          this.state.selectedDestinationdetails.airport_longitude,
        thumbnail: this.state.selectedDestinationdetails.thumbnail,
        images: this.state.selectedDestinationdetails.images,
        childAgeList: this.state.childAgeList,
        trip_type: "Return",
        transfer_trip_type: "Return",
        trip_count: daysDifference,
        sourceCountry: this.state.source_packagecountry,
        destinationCountry: this.state.selectedDestinationdetails.country_name,
        sourceCity: this.state.source_packageCity,
        destinationCity: this.state.selectedDestinationdetails.city_name,
        destinationWebbedsCity:
          this.state.selectedDestinationdetails.webbeds_city_code,
        sourceLat: this.state.source_airport_lat,
        sourceLon: this.state.source_airport_lon,
        destinationLat: this.state.selectedDestinationdetails.airport_latitude,
        destinationLon: this.state.selectedDestinationdetails.airport_longitude,
        airportLat: this.state.selectedDestinationdetails.airport_latitude,
        airportLon: this.state.selectedDestinationdetails.airport_longitude,
        packageType: this.state.packageType,
        priceFilter: this.state.price,
      };

      this.props.history.push({
        pathname: "/search-package",
        state: packagedetails,
      });
    }
  }
  selectPacakgeType(packageType) {
    this.setState({ packageType: packageType });
  }

  handlePriceChange = (event, newValue) => {
    this.setState({ price: { min: newValue[0], max: newValue[1] } });
  };
  viewMore = (segmentName) => {
    var segmentData = this.state.homePackages.find(
      (segment) => segment.segment_name === segmentName
    );
    console.log(segmentData, "segmentData");
    var packagedetails = {
      start_date: this.state.start_date || new Date(),
      end_date: this.state.end_date || new Date(),
      selectedMonth: this.state.selectedMonth,
      search_days: "2-5",
      adults: 1,
      child: this.state.child,
      infant: this.state.infant,
      room_count: 1,
      source_airport_code: "BLR",
      location_source:
        "Kempegowda International Airport Bengaluru (BLR), KIAL Rd, Devanahalli, Bengaluru, Karnataka 560300, India",
      source_airport_id: "2800",
      source_airport_address: "BLR",
      source_airport_lat: "12.994112",
      source_airport_lon: "80.1708668",
      destination_airport_code: "",
      location_destination: "",
      destination_airport_id: "",
      destination_airport_address: "",
      destination_airport_lat: "",
      destination_airport_lon: "",
      thumbnail: "",
      images: "",
      childAgeList: this.state.childAgeList,
      trip_type: "Return",
      transfer_trip_type: "Return",
      trip_count: "",
      sourceCountry: "India",
      destinationCountry: "",
      sourceCity: "",
      destinationCity: "",
      destinationWebbedsCity: "",
      sourceLat: "12.994112",
      sourceLon: "80.1708668",
      destinationLat: "",
      destinationLon: "",
      airportLat: "",
      airportLon: "",
      packageType: "uop",
      packageMode: "homeLandingPackages",
      homePackages: segmentData,
    };
    this.props.history.push({
      pathname: "/search-package",
      state: packagedetails,
    });
  };

  handleWhatsAppLink = () => {
    window.open("https://wa.me/6580333606", "_blank");
  };

  handleSearchVacation = () => {
    if (this.state.isMobile) {
      this.setState({ openSearchBottomSheet: true });
    } else {
      this.setState({ openSearchPopup: true });
    }
  };

  display_hyper() {
    var hyper_pack = this.state.display_home_package["Customizable"]?.map(
      (segmentContent, index) => (
        <div className="main-content" key={index}>
          {/* Display segment name */}
          <OwlCarousel
            key={`carousel-${index}`}
            ref={segmentContent.carouselRef} // Use the ref specific to this segment
            options={hypercustomOption}
          >
            {segmentContent.isLoading ? (
              <div className="search-loader">
                {[...Array(this.state?.isMobile ? 1 : 3)].map((item, index) => (
                  <ContentLoader
                    key={index}
                    viewBox="0 0 380 280"
                    height={280}
                    width={380}
                    speed={3}
                  >
                    <rect
                      x="3"
                      y="3"
                      rx="10"
                      ry="10"
                      width="380"
                      height="180"
                    />
                    <rect x="6" y="190" rx="0" ry="0" width="380" height="20" />
                    <rect x="4" y="215" rx="0" ry="0" width="320" height="20" />
                    <rect x="4" y="242" rx="0" ry="0" width="380" height="20" />
                  </ContentLoader>
                ))}
              </div>
            ) : (
              segmentContent.packages
            )}
          </OwlCarousel>
        </div>
      )
    );
    this.setState({
      hyper_packages: hyper_pack,
    });
  }
  loadJson() {
    this.setState(
      {
        airportsData: airportsData,
        getCityList: getCityList,
        getCountry: getCountry,
        getflightminprices: getflightminprices,
        getActivities: getActivities,
        LandingPackages: packages,
        getcityData: getcityData,
        getcities: getcities,
        getReviews: getReviews,
        homePackageLoading: false,
        showHomePackages: true,
      },
      () => {
        this.getPackage();
        this.display_hyper();
      }
    );
  }
  setRecentSearch = (country, field) => {
    const recentSearch = {
      airport_name: country.airport_name,
      airport_city_name: country.airport_city_name,
      airport_code: country.airport_code,
      id: country.id,
    };

    this.setState((prevState) => {
      const updatedRecentSearches = [
        recentSearch,
        ...prevState.recentSearchesList.filter(
          (item) => item.airport_code !== country.airport_code
        ),
      ].slice(0, 5);

      return {
        recentSearchesList: updatedRecentSearches,
        ...(field === "from" && {
          fromCityInput: country.airport_city_name || country.airport_name,
          source_airport_code: country.airport_code,
          sourceCityItem: country,
          fromCityList: [],
        }),
        ...(field === "to" && {
          toCityInput: country.airport_city_name || country.airport_name,
          destination_airport_code: country.airport_code,
          desCityItem: country,
          toCityList: [],
        }),
        showSearchResults: false,
      };
    });
  };

  handleScroll = () => {
    // Set the state to true when the user scrolls
    if (!this.state.shouldLoad) {
      console.log("User is scrolling! Triggering recommended content.");
      this.setState({ shouldLoad: true });
    }
  };
  monthFilter = (month) => {
    this.setState({ selectedMonth: month });
  };
  toggleShowMore = () => {
    this.setState((prevState) => ({
      showMoreMonths: !prevState.showMoreMonths,
    }));
  };
  render() {
    var totalpassenger = "";
    if (this.state.adults > 0) {
      var auname = "Adults";
      if (this.state.adults == 1) {
        auname = "Adult";
      }
      totalpassenger += auname + " X" + this.state.adults + " ";
    }
    if (this.state.child > 0) {
      var chname = "Children";
      if (this.state.child == 1) {
        chname = "Child";
      }
      totalpassenger += chname + " X" + this.state.child + " ";
    }
    if (this.state.infant > 0) {
      var inname = "Infants";
      if (this.state.infant == 1) {
        inname = "Infant";
      }
      totalpassenger += inname + " X" + this.state.infant + " ";
    }

    var current = new Date();
    current.setDate(current.getDate() + 1);
    var formattedDate = current.toDateString() + " " + current.toTimeString();
    var currentDate = new Date(formattedDate);

    return (
      <div className={`${this.state.isMobile ? "home-mb-container" : ""}`}>
        <Helmet>
          <title>Customized Holiday Packages 2024</title>
          <link rel="canonical" href="https://www.fizota.com/" />
          <meta
            name="description"
            content="Hyper customize your vacation online or explore curated unique vacations from top creators. Don't wait and book your adventure today!"
          />
          <meta
            name="keywords"
            content="weekend getaways, weekend getaways packages, holiday package, holiday packages overseas, holiday packages online, holiday packages 2024, vacation travel packages, travel packages online, travel packages 2024, all inclusive holidays packages, travel itinerary planner, travel planner vs travel agent, travel trip planner, customized holiday packages"
          />
          {/* Add more meta tags or other head elements as needed */}
        </Helmet>
        <Header
          {...this.props}
          openBottomSheet={(param) => {
            this.handleSearchFromHeader(param);
          }}
          sateValChange={this.sateValChange}
          showDestinationPopup={this.state.showDestinationPopup}
          openSearchPopup={this.state.openSearchPopup}
          closeSearchPopup={() => this.setState({ openSearchPopup: false })}
          closeDestinationPopup={() =>
            this.setState({ showDestinationPopup: false })
          }
          handleSearchVacation={this.handleSearchVacation}
          priceVal={this.state.price}
          selectedDestinationdetails={this.state.selectedDestinationdetails}
          opendestinationList={this.state.opendestinationList}
          searchCityList={this.state.searchCityList}
          openPopup={(id) => this.openPopup(id)}
          start_date={this.state.start_date}
          end_date={this.state.end_date}
          filterSearchCity={this.filterSearchCity.bind(this)}
          selectNewDestination={this.selectNewDestination.bind(this)}
          selectedDestination={this.state.selectedDestination}
          searchkeywords={this.state.searchkeywords}
          clearSearchkeywords={() => this.setState({ searchkeywords: "" })}
          handleTextChange={this.handleTextChange.bind(this)}
          selectPacakgeType={this.selectPacakgeType.bind(this)}
          packageType={this.state.packageType}
          continueExplore={this.continueExplore.bind(this)}
          error_location_source={this.state.error_location_source}
          error_start_date={this.state.error_start_date}
          error_month={this.state.error_month}
          clearSearch={this.clearSearch.bind(this)}
          handlePriceChange={this.handlePriceChange.bind(this)}
          monthFilter={this.monthFilter.bind(this)}
          selectedMonth={this.state.selectedMonth}
        />
        <PackageLeadDialog
          open={this.state.showPackageLeadPopup}
          selectAutoPackageLeadPopup={this.state.selectAutoPackageLeadPopup}
          handleClose={() =>
            this.setState({
              showPackageLeadPopup: false,
            })
          }
          data={this.state.selectedItem}
          viewPackage={this.continuePackage.bind(this)}
          locationData={this.state}
        />
        <div className="sub-header-main">
          <Tabs
            selectedTab={this.state.selectedTab}
            setSelectedTab={(val) => this.setState({ selectedTab: val })}
          />
        </div>
        <div>
          {/* <div>
            {this.state.isFlight === true && (
              <div className="step-banner-rounded flight-search-main flight-bg">
                <div className="container">
                  <div className="hero-content">
                    <div className="hero-content-top">
                      <h1>
                        Explore & book best flights from <br />
                        600+ airlines
                      </h1>
                    </div>
                  </div>
                  <div className={"flight-way-search flight-search-container"}>
                    <div className="flight-way">
                      <FormControl>
                        <RadioGroup
                          aria-labelledby="demo-controlled-radio-buttons-group"
                          name="controlled-radio-buttons-group"
                          value={this.state.trip_type}
                          onChange={this.handleRadioChange}
                          row
                        >
                          <FormControlLabel
                            value="OneWay"
                            control={
                              <Radio
                                sx={{
                                  "& .MuiSvgIcon-root": {
                                    color: "#4258bf",
                                  },
                                }}
                              />
                            }
                            label="One way"
                            sx={{
                              "& .MuiTypography-root": {
                                fontFamily: "Poppins",
                              },
                            }}
                          />
                          <FormControlLabel
                            value="Return"
                            control={
                              <Radio
                                sx={{
                                  "& .MuiSvgIcon-root": {
                                    color: "#4258bf",
                                  },
                                }}
                              />
                            }
                            label="Return"
                            sx={{
                              "& .MuiTypography-root": {
                                fontFamily: "Poppins",
                              },
                            }}
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                    <div className="flight-search rel">
                      <div className="fs-location">
                        <div className="fs-from">
                          <input
                            type="text"
                            placeholder="From"
                            ref={this.cityFromRef}
                            onMouseEnter={(e) =>
                              this.handleMouseEnter("CITY_FROM")
                            }
                            value={this.state.fromCityInput}
                            onClick={0}
                            onChange={(e) =>
                              this.handleCityChange(e, "fromCityInput")
                            }
                          />
                          {this.state.fromCityInput !== "" &&
                            this.state.isHovered === "CITY_FROM" &&
                            this.state.fromCityList.length > 0 && (
                              <div className="flight-results scroll-view">
                                {this.state.fromCityList.map((country) => (
                                  <div
                                    key={country.id}
                                    className="row"
                                    onClick={() =>
                                      this.setState({
                                        source_airport_code:
                                          country.airport_code,
                                        fromCityList: [],
                                        fromCityInput:
                                          country.airport_city_name,
                                        sourceCityItem: country,
                                      })
                                    }
                                  >
                                    <div className="col-1">
                                      <div className="city">
                                        {country.airport_name}
                                      </div>
                                      <div className="airport">
                                        {country.airport_city_name}
                                      </div>
                                    </div>
                                    <div className="col-2">
                                      {country.airport_code}
                                    </div>
                                  </div>
                                ))}
                              </div>
                            )}
                        </div>
                        <div className="fs-swap">
                          <img
                            src={swap}
                            alt="swap"
                            onClick={this.setSwapRoute.bind(this)}
                          />
                        </div>
                        <div className="fs-to">
                          <input
                            type="text"
                            placeholder="To"
                            ref={this.cityToRef}
                            onMouseEnter={(e) =>
                              this.handleMouseEnter("CITY_TO")
                            }
                            value={this.state.toCityInput}
                            onChange={(e) =>
                              this.handleCityChange(e, "toCityInput")
                            }
                          />
                          {this.state.toCityInput !== "" &&
                            this.state.isHovered === "CITY_TO" &&
                            this.state.toCityList.length > 0 && (
                              <div className="flight-results ">
                                {this.state.toCityList.map((country) => (
                                  <div
                                    key={country.id}
                                    className="row"
                                    onClick={() =>
                                      this.setState({
                                        destination_airport_code:
                                          country.airport_code,
                                        toCityInput: country.airport_city_name,
                                        toCityList: [],
                                        desCityItem: country,
                                      })
                                    }
                                  >
                                    <div className="col-1">
                                      <div className="city">
                                        {country.airport_name}
                                      </div>
                                      <div className="airport">
                                        {country.airport_city_name}
                                      </div>
                                    </div>
                                    <div className="col-2">
                                      {country.airport_code}
                                    </div>
                                  </div>
                                ))}
                              </div>
                            )}
                        </div>
                      </div>
                      <div className="fs-stay" ref={this.daterangeRef}>
                        <input
                          type="text"
                          placeholder={
                            this.state.trip_type === "Return"
                              ? "Start Date - End Date"
                              : "Start Date"
                          }
                          onMouseEnter={(e) =>
                            this.handleMouseEnter("DATE_RANGE")
                          }
                          value={
                            this.state.trip_type === "Return"
                              ? moment(
                                  moment(
                                    this.state.dateRange?.startDate
                                  ).format("DD-MM-YYYY")
                                ).isSame(moment().format("DD-MM-YYYY"))
                                ? null
                                : `${moment(
                                    this.state.dateRange?.startDate
                                  ).format("DD-MM-YYYY")} - ${moment(
                                    this.state.dateRange?.endDate
                                  ).format("DD-MM-YYYY")}`
                              : this.state.start_date
                              ? moment(this.state.start_date).format(
                                  "DD-MM-YYYY"
                                )
                              : null
                          }
                          onClick={() => {
                            this.setState({
                              openDateRange: !this.state.openDateRange,
                            });
                          }}
                        />
                        <img
                          src={bcal}
                          onClick={() => {
                            this.setState({
                              openDateRange: !this.state.openDateRange,
                            });
                          }}
                        />
                        {this.state.openDateRange &&
                        this.state.isHovered === "DATE_RANGE" ? (
                          <div className="flight-results dateMain">
                            {this.state.trip_type === "Return" ? (
                              <DateRange
                                showDateDisplay={false}
                                startDatePlaceholder={"Start Date"}
                                endDatePlaceholder={"End Date"}
                                editableDateInputs={false}
                                moveRangeOnFirstSelection={false}
                                ranges={[this.state.dateRange]}
                                onChange={this.handleSelectDateRange.bind(this)}
                                rangeColors={["#4258bf"]}
                                months={2}
                                minDate={new Date()}
                                direction="horizontal"
                              />
                            ) : (
                              <Calendar
                                onChange={this.handleSelectDate.bind(this)}
                                minDate={currentDate}
                                date={this.state.start_date}
                                months={2}
                                preventSnapRefocus={true}
                                direction="horizontal"
                              />
                            )}
                            <div className="btnMain">
                              <Button
                                variant="contained"
                                className="doneBtn"
                                style={{}}
                                onClick={() =>
                                  this.setState({
                                    openDateRange: !this.state.openDateRange,
                                  })
                                }
                              >
                                Done
                              </Button>
                            </div>
                          </div>
                        ) : null}
                      </div>
                      <div className="fs-whom" ref={this.passengerListRef}>
                        <input
                          type="text"
                          placeholder="Members"
                          onMouseEnter={(e) =>
                            this.handleMouseEnter("PASSENGER_LIST")
                          }
                          value={` ${
                            this.state.adults +
                            this.state.child +
                            this.state.infant
                          } Passengers`}
                          onClick={() =>
                            this.setState({ passengerPopup: true })
                          }
                        />
                        {this.state.passengerPopup === true &&
                          this.state.isHovered === "PASSENGER_LIST" && (
                            <div className="flight-results pass-dropdown step-content-passenger">
                              {this.passengerSelection()}
                              <div className="btnMain">
                                <Button
                                  variant="contained"
                                  className="doneBtn"
                                  style={{}}
                                  onClick={() =>
                                    this.setState({ passengerPopup: false })
                                  }
                                >
                                  Done
                                </Button>
                              </div>
                            </div>
                          )}
                        <img src={bprofile} alt="profile" />
                      </div>

                      <button
                        type="submit"
                        className="button fg-btn search_flight"
                        onClick={() => this.searchNewFlight()}
                      >
                        <img src={searchlight} />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div>
            {this.state.isVacation === true && (
              <Search
                cityList={this.state.cityList}
                selectNewDestination={this.selectNewDestination.bind(this)}
                continueExplore={this.continueExplore.bind(this)}
                selectionRange={this.state.selectionRange}
                handleSelectDateRangeSelectRange={this.handleSelectDateRangeSelectRange.bind(
                  this
                )}
              />
            )}
          </div> */}
          {/* {!this.state.isMobile && <div className="carousel-divider-web"></div>} */}
          <div>
            {this.state.isActivities === true && (
              <section
                className={`${
                  this.state.showsearchList ? "overflow-initial" : ""
                } step-banner-rounded ttd-bg`}
              >
                <div className="container">
                  <div className="hero-content">
                    <div className="hero-content-top">
                      <h1>
                        Explore & book wider range of
                        <br /> things to do{" "}
                      </h1>
                    </div>
                    <div className="hero-content-search-parent">
                      <div className="hero-content-search">
                        <input
                          type="text"
                          placeholder="Enter your activities"
                          name="search_keyword"
                          onChange={(e) => this.searchActivities(e)}
                        />
                      </div>
                      {!this.state.isMobile && this.state.showsearchList && (
                        <div
                          ref={this.dropdownRef}
                          className={
                            this.state.showsearchList === true
                              ? "search-place active"
                              : "search-place"
                          }
                        >
                          <ul>
                            {this.state.displaySearchCountry}
                            {this.state.displaySearchProducts}
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </section>
            )}
          </div>
          <div>
            {this.state.isStay === true && (
              <StaySeparate isStay={true} state={this.state} />
            )}
          </div>
        </div>
        {this.state.selectedTab === "flight" ? (
          <>
            <div className="flightSearch-main">
              <div className="step-banner-rounded flight-search-main">
                <div className={"flight-way-search flight-search-container"}>
                  <div className="flight-way">
                    <FormControl>
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={this.state.trip_type}
                        onChange={this.handleRadioChange}
                        row
                      >
                        <FormControlLabel
                          value="OneWay"
                          control={
                            <Radio
                              sx={{
                                "& .MuiSvgIcon-root": {
                                  color: "#4258bf",
                                },
                              }}
                            />
                          }
                          label="One way"
                          sx={{
                            "& .MuiTypography-root": {
                              fontFamily: "Poppins",
                            },
                          }}
                          className={`${
                            this.state.trip_type === "OneWay" && "selected-type"
                          }`}
                        />
                        &nbsp;
                        <FormControlLabel
                          value="Return"
                          control={
                            <Radio
                              sx={{
                                "& .MuiSvgIcon-root": {
                                  color: "#4258bf",
                                },
                              }}
                            />
                          }
                          label="Round trip"
                          sx={{
                            "& .MuiTypography-root": {
                              fontFamily: "Poppins",
                            },
                          }}
                          className={`${
                            this.state.trip_type === "Return" && "selected-type"
                          }`}
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                  <div className="flight-search rel">
                    <div className="fs-location">
                      <div className="fs-from">
                        {this.state.fromCityInput && (
                          <div className="label">From</div>
                        )}
                        <input
                          type="text"
                          placeholder="From"
                          ref={this.cityFromRef}
                          onMouseEnter={(e) =>
                            this.handleMouseEnter("CITY_FROM")
                          }
                          value={this.state.fromCityInput}
                          onClick={() =>
                            this.state.isMobile
                              ? this.handleFlightSearchSheet("from")
                              : 0
                          }
                          onChange={(e) =>
                            !this.state.isMobile &&
                            this.handleCityChange(e, "fromCityInput")
                          }
                        />
                        {this.state.fromCityInput !== "" &&
                          this.state.source_airport_code && (
                            <div className="sub-text">
                              {this.state.source_airport_code},{" "}
                              {this.state.sourceCityItem?.airport_name}
                            </div>
                          )}
                        {!this.state.isMobile &&
                          this.state.showSearchResults &&
                          this.state.fromCityList?.searchResults?.length >
                            0 && (
                            <div className="flight-results scroll-view">
                              {this.state.recentSearchesList?.map((country) => (
                                <div
                                  key={country.id}
                                  className="row"
                                  onClick={() =>
                                    this.setRecentSearch(country, "from")
                                  }
                                >
                                  <div className="col-1">
                                    <div className="city">
                                      {country.airport_name}
                                    </div>
                                    <div className="airport">
                                      {country.airport_city_name}
                                    </div>
                                  </div>
                                  <div className="col-2">
                                    {country.airport_code}
                                  </div>
                                </div>
                              ))}
                              {this.state.fromCityList?.searchResults?.map(
                                (country) => (
                                  <div
                                    key={country.id}
                                    className="row"
                                    onClick={() => {
                                      this.setRecentSearch(country);
                                      this.setState({
                                        source_airport_code:
                                          country.airport_code,
                                        fromCityList: [],
                                        fromCityInput:
                                          country.airport_city_name ||
                                          country?.airport_name,
                                        sourceCityItem: country,
                                        showSearchResults: false,
                                      });
                                    }}
                                  >
                                    <div className="col-1">
                                      <div className="city">
                                        {country.airport_name}
                                      </div>
                                      <div className="airport">
                                        {country.airport_city_name}
                                      </div>
                                    </div>
                                    <div className="col-2">
                                      {country.airport_code}
                                    </div>
                                  </div>
                                )
                              )}
                            </div>
                          )}
                        {this.state.source_airport_error !== "" && (
                          <div className="input_valid_error">
                            {this.state.source_airport_error}
                          </div>
                        )}
                      </div>
                      {this.state.isMobile ? (
                        <img
                          src={SwapImg}
                          className="fs-swap-img"
                          onClick={this.setSwapRoute.bind(this)}
                        />
                      ) : (
                        <div className="fs-swap">
                          <div className="vl"></div>
                          <img
                            src={SwapImg}
                            onClick={this.setSwapRoute.bind(this)}
                          />
                        </div>
                      )}
                      <div className="fs-to">
                        {this.state.toCityInput && (
                          <div className="label">To</div>
                        )}
                        <input
                          type="text"
                          placeholder="To"
                          ref={this.cityToRef}
                          onMouseEnter={(e) => this.handleMouseEnter("CITY_TO")}
                          onClick={() =>
                            this.state.isMobile
                              ? this.handleFlightSearchSheet("to")
                              : 0
                          }
                          value={this.state.toCityInput}
                          onChange={(e) =>
                            !this.state.isMobile &&
                            this.handleCityChange(e, "toCityInput")
                          }
                        />
                        {this.state.toCityInput !== "" &&
                          this.state.destination_airport_code && (
                            <div className="sub-text">
                              {this.state.destination_airport_code},{" "}
                              {this.state.desCityItem?.airport_name}
                            </div>
                          )}
                        {!this.state.isMobile &&
                          this.state.toCityList?.searchResults?.length > 0 && (
                            <div className="flight-results scroll-view">
                              {this.state.recentSearchesList?.map((country) => (
                                <div
                                  key={country.id}
                                  className="row"
                                  onClick={() =>
                                    this.setRecentSearch(country, "to")
                                  }
                                >
                                  <div className="col-1">
                                    <div className="city">
                                      {country.airport_name}
                                    </div>
                                    <div className="airport">
                                      {country.airport_city_name}
                                    </div>
                                  </div>
                                  <div className="col-2">
                                    {country.airport_code}
                                  </div>
                                </div>
                              ))}
                              {this.state.toCityList?.searchResults
                                ?.filter(
                                  (country) =>
                                    country.airport_code !==
                                    this.state.source_airport_code
                                )
                                .map((country) => (
                                  <div
                                    key={country.id}
                                    className="row"
                                    onClick={() => {
                                      this.setRecentSearch(country);
                                      this.setState({
                                        destination_airport_code:
                                          country.airport_code,
                                        toCityInput:
                                          country.airport_city_name ||
                                          country?.airport_name,
                                        toCityList: [],
                                        desCityItem: country,
                                      });
                                    }}
                                  >
                                    <div className="col-1">
                                      <div className="city">
                                        {country.airport_name}
                                      </div>
                                      <div className="airport">
                                        {country.airport_city_name}
                                      </div>
                                    </div>
                                    <div className="col-2">
                                      {country.airport_code}
                                    </div>
                                  </div>
                                ))}
                            </div>
                          )}
                        {this.state.destination_airport_error !== "" && (
                          <div className="input_valid_error">
                            {this.state.destination_airport_error}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="fs-stay" ref={this.openFromDateRangeRef}>
                      <div className="label">Departure</div>
                      <div className="input-main">
                        <input
                          type="text"
                          placeholder={"Departure Date"}
                          readOnly={true}
                          onMouseEnter={(e) =>
                            !this.state.isMobile &&
                            this.handleMouseEnter("DATE_RANGE")
                          }
                          value={
                            this.state.start_date
                              ? moment(this.state.start_date).format(
                                  "DD MMM,YYYY"
                                )
                              : null
                          }
                          onClick={() =>
                            this.state.isMobile
                              ? this.handleFlightSearchSheet("fromtripdate")
                              : this.state.trip_type === "OneWay"
                              ? this.setState({
                                  openFromDateRange:
                                    !this.state.openFromDateRange,
                                  openToDateRange: false,
                                  fromdate_airport_error: "",
                                })
                              : this.setState({
                                  fromRange:
                                    this.state.start_date !== "" && true,
                                  openFromDateRange:
                                    this.state.start_date === "" &&
                                    !this.state.fromRange &&
                                    !this.state.openFromDateRange,
                                  openToDateRange: false,
                                  fromdate_airport_error: "",
                                })
                          }
                        />
                        {this.state.fromdate_airport_error !== "" && (
                          <div className="input_valid_error">
                            {this.state.fromdate_airport_error}
                          </div>
                        )}
                        {this.state.fromRange && (
                          <div className="flight-results dateMain">
                            <h3>Choose a Departure date</h3>
                            <DateRange
                              showDateDisplay={false}
                              editableDateInputs={false}
                              moveRangeOnFirstSelection={false}
                              ranges={[this.state.dateRange]}
                              onChange={this.handleSelectDateReturn.bind(this)}
                              rangeColors={["#4258bf"]}
                              months={this.state.isMobile ? 1 : 2}
                              minDate={new Date()}
                              direction="horizontal"
                            />
                          </div>
                        )}
                        {this.state.openFromDateRange ? (
                          <div className="flight-results dateMain">
                            <h3>Choose a Departure date</h3>
                            <Calendar
                              onChange={this.handleSelectDate.bind(this)}
                              minDate={new Date()}
                              date={this.state.start_date}
                              months={this.state.isMobile ? 1 : 2}
                              preventSnapRefocus={true}
                              direction="horizontal"
                            />
                            <div className="btnMain">
                              {this.state.trip_type !== "Return" && (
                                <Button
                                  variant="contained"
                                  className="doneBtn"
                                  style={{}}
                                  onClick={() =>
                                    this.setState({
                                      openFromDateRange:
                                        !this.state.openFromDateRange,
                                      fromdate_airport_error: "",
                                      openToDateRange: true,
                                    })
                                  }
                                >
                                  Done
                                </Button>
                              )}
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    {this.state.trip_type === "Return" && (
                      <div className="fs-stay" ref={this.openToDateRangeRef}>
                        <div className="label">Return</div>
                        <div className="input-main">
                          <input
                            type="text"
                            readOnly={true}
                            placeholder={"Return Date"}
                            onMouseEnter={(e) =>
                              !this.state.isMobile &&
                              this.handleMouseEnter("DATE_RANGE2")
                            }
                            value={
                              this.state.end_date
                                ? moment(this.state.end_date).format(
                                    "DD MMM,YYYY"
                                  )
                                : null
                            }
                            onClick={() =>
                              this.state.isMobile
                                ? this.handleFlightSearchSheet("totripdate")
                                : this.setState({
                                    openToDateRange:
                                      this.state.start_date === ""
                                        ? false
                                        : !this.state.openToDateRange,
                                    openFromDateRange: false,
                                    fromRange: false,
                                    fromdate_airport_error:
                                      this.state.start_date === ""
                                        ? "please enter departure date"
                                        : "",
                                  })
                            }
                          />
                          {this.state.todate_airport_error !== "" && (
                            <div className="input_valid_error">
                              {this.state.todate_airport_error}
                            </div>
                          )}
                          {this.state.openToDateRange ? (
                            <div className="flight-results dateMain">
                              <h3>Choose a Return date</h3>
                              <DateRange
                                showDateDisplay={false}
                                editableDateInputs={false}
                                moveRangeOnFirstSelection={false}
                                ranges={[this.state.dateRange]}
                                onChange={this.handleSelectDateRange.bind(this)}
                                rangeColors={["#4258bf"]}
                                months={this.state.isMobile ? 1 : 2}
                                minDate={this.state.start_date}
                                direction="horizontal"
                              />
                              {/* <Calendar
                                 showDateDisplay={false}
                                 // startDatePlaceholder={"Start Date"}
                                 endDatePlaceholder={"End Date"}
                                 editableDateInputs={false}
                                 moveRangeOnFirstSelection={false}
                                 ranges={[this.state.dateRange]}
                                 onChange={this.handleSelectDateRange.bind(this)}
                                 rangeColors={["#4258bf"]}
                                 months={this.state.isMobile ? 1 : 2}
                                //  minDate={new Date()}
                                 direction="horizontal"
                              /> */}
                              <div className="btnMain">
                                <Button
                                  variant="contained"
                                  className="doneBtn"
                                  style={{}}
                                  onClick={() =>
                                    this.setState({
                                      openToDateRange:
                                        !this.state.openToDateRange,
                                      todate_airport_error: "",
                                    })
                                  }
                                >
                                  Done
                                </Button>
                              </div>
                            </div>
                          ) : null}
                        </div>
                        {/* {this.state.fromdate_airport_error !== "" && (
                          <div className="input_valid_error">
                            {this.state.fromdate_airport_error}
                          </div>
                        )} */}
                      </div>
                    )}
                    <div className="fs-whom" ref={this.passengerListRef}>
                      <div className="label">No of Passengers</div>
                      <div className="p-main">
                        <input
                          type="text"
                          placeholder="Members"
                          onMouseEnter={(e) =>
                            this.handleMouseEnter("PASSENGER_LIST")
                          }
                          value={` ${
                            this.state.adults +
                            this.state.child +
                            this.state.infant
                          } Travelers`}
                          onClick={() =>
                            this.state.isMobile
                              ? this.handleFlightSearchSheet("passenger")
                              : this.setState({ passengerPopup: true })
                          }
                        />
                        {this.state.passengerPopup === true &&
                          this.state.isHovered === "PASSENGER_LIST" && (
                            <div className="flight-results pass-dropdown step-content-passenger">
                              {this.passengerSelection()}
                              <div className="btnMain">
                                <Button
                                  variant="contained"
                                  className="doneBtn"
                                  style={{}}
                                  onClick={() =>
                                    this.setState({ passengerPopup: false })
                                  }
                                >
                                  Done
                                </Button>
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                  <Button
                    variant="contained"
                    type="submit"
                    className="button search_flight"
                    onClick={() => this.searchNewFlight()}
                  >
                    Search
                  </Button>
                </div>
              </div>
            </div>
          </>
        ) : null}
        <>
          {/* <section
            className={`mobile-banner-search ${
              this.state.isFlight ? "flight-search-main" : ""
            }`}
          >
            <div className="container">
              <div className="step-banner-lhs">
                {this.state.isActivities ? (
                  <h1>
                    Explore & book wider range
                    <br /> of things to do{" "}
                  </h1>
                ) : this.state.isFlight ? (
                  <h1>
                    Explore & book best flights from <br />
                    600+ airlines
                  </h1>
                ) : (
                  <h1>
                    Customize and book your
                    <br /> Next vacation.
                  </h1>
                )}
              </div>
              {this.state.isFlight === true ? (
                <div className={"flight-way-search flight-search-container"}>
                  <div className="flight-way">
                    <FormControl>
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={this.state.trip_type}
                        onChange={this.handleRadioChange}
                        row
                      >
                        <FormControlLabel
                          value="OneWay"
                          control={
                            <Radio
                              sx={{
                                "& .MuiSvgIcon-root": {
                                  color: "#4258bf",
                                },
                              }}
                            />
                          }
                          label="One way"
                          sx={{
                            "& .MuiTypography-root": {
                              fontFamily: "Poppins",
                            },
                          }}
                        />
                        <FormControlLabel
                          value="Return"
                          control={
                            <Radio
                              sx={{
                                "& .MuiSvgIcon-root": {
                                  color: "#4258bf",
                                },
                              }}
                            />
                          }
                          label="Return"
                          sx={{
                            "& .MuiTypography-root": {
                              fontFamily: "Poppins",
                            },
                          }}
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                  <div className="flight-search rel">
                    <div className="fs-location">
                      <div className="fs-from">
                        <input
                          type="text"
                          placeholder="From"
                          value={this.state.fromCityInput}
                          onClick={0}
                          onChange={(e) =>
                            this.handleCityChange(e, "fromCityInput")
                          }
                        />
                        {this.state.fromCityInput !== "" &&
                          this.state.fromCityList.length > 0 && (
                            <div className="flight-results">
                              {this.state.fromCityList.map((country) => (
                                <div
                                  key={country.id}
                                  className="row"
                                  onClick={() =>
                                    this.setState({
                                      source_airport_code: country.airport_code,
                                      fromCityList: [],
                                      fromCityInput: country.airport_city_name,
                                      sourceCityItem: country,
                                    })
                                  }
                                >
                                  <div className="col-1">
                                    <div className="city">
                                      {country.airport_name}
                                    </div>
                                    <div className="airport">
                                      {country.airport_city_name}
                                    </div>
                                  </div>
                                  <div className="col-2">
                                    {country.airport_code}
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}
                      </div>
                      <div className="fs-swap">
                        <img
                          src={swap}
                          alt="swap"
                          onClick={this.setSwapRoute.bind(this)}
                        />
                      </div>
                      <div className="fs-to">
                        <input
                          type="text"
                          placeholder="To"
                          value={this.state.toCityInput}
                          onChange={(e) =>
                            this.handleCityChange(e, "toCityInput")
                          }
                        />
                        {this.state.toCityInput !== "" &&
                          this.state.toCityList.length > 0 && (
                            <div className="flight-results">
                              {this.state.toCityList.map((country) => (
                                <div
                                  key={country.id}
                                  className="row"
                                  onClick={() =>
                                    this.setState({
                                      destination_airport_code:
                                        country.airport_code,
                                      toCityInput: country.airport_city_name,
                                      toCityList: [],
                                      desCityItem: country,
                                    })
                                  }
                                >
                                  <div className="col-1">
                                    <div className="city">
                                      {country.airport_name}
                                    </div>
                                    <div className="airport">
                                      {country.airport_city_name}
                                    </div>
                                  </div>
                                  <div className="col-2">
                                    {country.airport_code}
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}
                      </div>
                    </div>
                    <div className="fs-stay">
                      <input
                        type="text"
                        placeholder={
                          this.state.trip_type === "Return"
                            ? "Start Date - End Date"
                            : "Start Date"
                        }
                        value={
                          this.state.trip_type === "Return"
                            ? moment(
                                moment(this.state.dateRange?.startDate).format(
                                  "DD-MM-YYYY"
                                )
                              ).isSame(moment().format("DD-MM-YYYY"))
                              ? null
                              : `${moment(
                                  this.state.dateRange?.startDate
                                ).format("DD-MM-YYYY")} - ${moment(
                                  this.state.dateRange?.endDate
                                ).format("DD-MM-YYYY")}`
                            : this.state.start_date
                            ? moment(this.state.start_date).format("DD-MM-YYYY")
                            : null
                        }
                        onClick={() => {
                          this.setState({
                            openDateRange: !this.state.openDateRange,
                          });
                        }}
                      />
                      <img
                        src={bcal}
                        onClick={() => {
                          this.setState({
                            openDateRange: !this.state.openDateRange,
                          });
                        }}
                      />
                      {this.state.openDateRange ? (
                        <div className="flight-results dateMain">
                          {this.state.trip_type === "Return" ? (
                            <DateRange
                              showDateDisplay={false}
                              startDatePlaceholder={"Start Date"}
                              endDatePlaceholder={"End Date"}
                              editableDateInputs={false}
                              moveRangeOnFirstSelection={false}
                              ranges={[this.state.dateRange]}
                              onChange={this.handleSelectDateRange.bind(this)}
                              rangeColors={["#4258bf"]}
                              months={1}
                              minDate={new Date()}
                              direction="horizontal"
                            />
                          ) : (
                            <Calendar
                              onChange={this.handleSelectDate.bind(this)}
                              minDate={currentDate}
                              date={this.state.start_date}
                              months={2}
                              preventSnapRefocus={true}
                              direction="horizontal"
                            />
                          )}
                          <div className="btnMain">
                            <Button
                              variant="contained"
                              className="doneBtn"
                              style={{}}
                              onClick={() =>
                                this.setState({
                                  openDateRange: !this.state.openDateRange,
                                })
                              }
                            >
                              Done
                            </Button>
                          </div>
                        </div>
                      ) : null}
                    </div>
                    <div className="fs-whom">
                      <input
                        type="text"
                        placeholder="Members"
                        value={` ${
                          this.state.adults +
                          this.state.child +
                          this.state.infant
                        } Passengers`}
                        onClick={() => this.setState({ passengerPopup: true })}
                      />
                      {this.state.passengerPopup === true && (
                        <div className="flight-results pass-dropdown step-content-passenger">
                          {this.passengerSelection()}
                          <div className="btnMain">
                            <Button
                              variant="contained"
                              className="doneBtn"
                              style={{}}
                              onClick={() =>
                                this.setState({ passengerPopup: false })
                              }
                            >
                              Done
                            </Button>
                          </div>
                        </div>
                      )}
                      <img src={bprofile} alt="profile" />
                    </div>

                    <button
                      type="submit"
                      className="button fg-btn search_flight"
                      onClick={() => this.searchNewFlight()}
                    >
                      <img src={searchlight} />
                    </button>
                  </div>
                </div>
              ) : this.state.isVacation ? (
                <div className="cc-search">
                  <div className="hero-content-search-parent">
                    <div className="hero-content-search">
                      <input
                        type="text"
                        className="m-search"
                        placeholder="Search vacation"
                        onClick={() =>
                          this.setState({
                            openSearchBottomSheet: true,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="hero-content-search-parent">
                  <div className="hero-content-search">
                    <input
                      type="text"
                      placeholder="Enter your activities"
                      name="search_keyword"
                      onChange={(e) => this.searchActivities(e)}
                    />
                  </div>
                  {this.state.showsearchList && (
                    <div
                      ref={this.dropdownRef}
                      className={
                        this.state.showsearchList === true
                          ? "search-place active"
                          : "search-place"
                      }
                    >
                      <ul>
                        {this.state.displaySearchCountry}
                        {this.state.displaySearchProducts}
                      </ul>
                    </div>
                  )}
                </div>
              )}
              {this.state.mainCityList.length > 0 && this.state.isVacation && (
                <div className="hero-content-promt">
                  <ul>
                    {this.state.mainCityList.map((item, index) => {
                      return (
                        <li key={index}>
                          <a
                            href={void 0}
                            onClick={() => {
                              this.setState({
                                mobileStep: 2,
                                openBottomSheet: true,
                              });
                              this.selectCountryPackages(
                                item.country_name,
                                item.city_name
                              );
                            }}
                          >
                            <span>{item.city_name}</span>
                            <img src={prarrow} alt="Arrow" />
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}
            </div>
          </section> */}
          {/* PACKAGES CAROUSAL SECTION*/}
          <section
            className={`pack-and-step ${
              this.state.isMobile && this.state.isFlight
                ? "mobile-flight-way-search"
                : ""
            }`}
          >
            <div className="container container-overflow">
              {/* DISCOUNT PACKAGES */}
              {/* {this.state.isMobile ? <InfoCarousel /> : null} */}

              {/* BANNER IMAGES */}
              {/* <BannerCarousel
                isMobile={this.state.isMobile}
                slectedbanner={this.selectCountryPackages.bind(this)}
              /> */}

              {this.state.selectedTab === "vacation" ? (
                <>
                  {/* CITY LANDING PAGE SECTION */}
                  {this.state.recommendDestinations?.length ? (
                    <div className="destination-trend">
                      <div className="title-with-description">
                        <div className="title-col">
                          <h2>Top recommended destinations by Fizota</h2>
                        </div>
                        <div className="action-btns">
                          <div
                            className="prevBtn"
                            onClick={() => this.carouselRef.current.prev()}
                          >
                            <i
                              className="fa fa-angle-left"
                              aria-hidden="true"
                            ></i>
                          </div>
                          &nbsp;&nbsp;
                          <div
                            className="nextBtn"
                            onClick={() => this.carouselRef.current.next()}
                          >
                            <i
                              className="fa fa-angle-right"
                              aria-hidden="true"
                            ></i>
                          </div>
                        </div>
                      </div>

                      <div className="main-content">
                        <OwlCarousel
                          ref={this.carouselRef}
                          options={destoption}
                        >
                          {this.state.recommendDestinations?.map(
                            (item, index) => (
                              <div key={index}>
                                <a
                                  href={void 0}
                                  onClick={() => {
                                    this.selectCountryPackages(
                                      item?.country_name,
                                      item?.city_name
                                    );
                                  }}
                                  className="cover-parent"
                                >
                                  <div className="curate-img">
                                    <img
                                      src={item?.images}
                                      alt={"singapore"}
                                      loading="lazy"
                                    />
                                  </div>
                                  <div className="curate-def">
                                    {/* <p>Cosmopolitan</p> */}
                                    <span>{item?.city_name}</span>
                                    <span className="price">
                                      Price starts from{" "}
                                      {showPriceValue(
                                        item?.min_price,
                                        this.state.currentCurrency,
                                        this.state.currencyRate
                                      )}
                                    </span>
                                  </div>
                                </a>
                              </div>
                            )
                          )}
                        </OwlCarousel>
                      </div>
                    </div>
                  ) : null}
                  {this.state.display_home_package["Customizable"]?.length ? (
                    <div className="hyper-customize-main">
                      <div className="col-1">
                        <div className="heading">
                          Hyper customize and book your vacation now!
                        </div>
                        <div className="list">
                          {["Flights", "Activities", "Stay", "Transport"].map(
                            (item) => (
                              <div>
                                <img src={TickImg} /> {item}
                              </div>
                            )
                          )}
                        </div>
                        <Button
                          className="watch-btn"
                          endIcon={<img src={watchImg} />}
                          onClick={() => this.handleWatchVideo()}
                        >
                          Watch how to customize
                        </Button>
                      </div>
                      <div className="col-2">
                        <div className="package-trend">
                          {this.state.homePackageLoading === true ? (
                            <div className="search-loader">
                              {[...Array(this.state?.isMobile ? 1 : 2)].map(
                                (item, index) => (
                                  <ContentLoader
                                    key={index}
                                    viewBox="0 0 380 280"
                                    height={280}
                                    width={380}
                                    speed={3}
                                  >
                                    <rect
                                      x="3"
                                      y="3"
                                      rx="10"
                                      ry="10"
                                      width="380"
                                      height="180"
                                    />
                                    <rect
                                      x="6"
                                      y="190"
                                      rx="0"
                                      ry="0"
                                      width="380"
                                      height="20"
                                    />
                                    <rect
                                      x="4"
                                      y="215"
                                      rx="0"
                                      ry="0"
                                      width="320"
                                      height="20"
                                    />
                                    <rect
                                      x="4"
                                      y="242"
                                      rx="0"
                                      ry="0"
                                      width="380"
                                      height="20"
                                    />
                                  </ContentLoader>
                                )
                              )}
                            </div>
                          ) : (
                            this.state.hyper_packages
                          )}
                        </div>
                      </div>
                    </div>
                  ) : null}

                  {this.state.shouldLoad && this.state.showHomePackages && (
                    <div className="package-trend">
                      <div className="top-10">
                        {this.state.homePackageLoading === true ? (
                          <div className="search-loader">
                            {[...Array(this.state?.isMobile ? 1 : 2)].map(
                              (item, index) => (
                                <ContentLoader
                                  key={index}
                                  viewBox="0 0 380 280"
                                  height={280}
                                  width={380}
                                  speed={3}
                                >
                                  <rect
                                    x="3"
                                    y="3"
                                    rx="10"
                                    ry="10"
                                    width="380"
                                    height="180"
                                  />
                                  <rect
                                    x="6"
                                    y="190"
                                    rx="0"
                                    ry="0"
                                    width="380"
                                    height="20"
                                  />
                                  <rect
                                    x="4"
                                    y="215"
                                    rx="0"
                                    ry="0"
                                    width="320"
                                    height="20"
                                  />
                                  <rect
                                    x="4"
                                    y="242"
                                    rx="0"
                                    ry="0"
                                    width="380"
                                    height="20"
                                  />
                                </ContentLoader>
                              )
                            )}
                          </div>
                        ) : (
                          this.state.display_home_package["Recommended"]?.map(
                            (segmentContent, index) => (
                              <div className="main-content" key={index}>
                                <div className="title-with-description">
                                  <div className="title-col">
                                    {index === 0 && (
                                      <h2>Top 10 vacation packages</h2>
                                    )}
                                    {/* <h2>{segmentContent.segmentName}</h2> */}
                                    <span>{segmentContent.segmentName}</span>
                                  </div>
                                  <div className="action-btns">
                                    <div
                                      className="prevBtn"
                                      onClick={() =>
                                        segmentContent.carouselRef.current.prev()
                                      }
                                    >
                                      <i
                                        className="fa fa-angle-left"
                                        aria-hidden="true"
                                      ></i>
                                    </div>
                                    &nbsp;&nbsp;
                                    <div
                                      className="nextBtn"
                                      onClick={() =>
                                        segmentContent.carouselRef.current.next()
                                      }
                                    >
                                      <i
                                        className="fa fa-angle-right"
                                        aria-hidden="true"
                                      ></i>
                                    </div>
                                  </div>
                                </div>{" "}
                                {/* Display segment name */}
                                <OwlCarousel
                                  key={`carousel-${index}`}
                                  ref={segmentContent.carouselRef} // Use the ref specific to this segment
                                  options={trendoption}
                                >
                                  {segmentContent.isLoading ? (
                                    <div className="search-loader">
                                      {[
                                        ...Array(this.state?.isMobile ? 1 : 3),
                                      ].map((item, index) => (
                                        <ContentLoader
                                          key={index}
                                          viewBox="0 0 380 280"
                                          height={280}
                                          width={380}
                                          speed={3}
                                        >
                                          <rect
                                            x="3"
                                            y="3"
                                            rx="10"
                                            ry="10"
                                            width="380"
                                            height="180"
                                          />
                                          <rect
                                            x="6"
                                            y="190"
                                            rx="0"
                                            ry="0"
                                            width="380"
                                            height="20"
                                          />
                                          <rect
                                            x="4"
                                            y="215"
                                            rx="0"
                                            ry="0"
                                            width="320"
                                            height="20"
                                          />
                                          <rect
                                            x="4"
                                            y="242"
                                            rx="0"
                                            ry="0"
                                            width="380"
                                            height="20"
                                          />
                                        </ContentLoader>
                                      ))}
                                    </div>
                                  ) : (
                                    segmentContent.packages
                                  )}
                                </OwlCarousel>
                                {this.state.display_home_package["Recommended"]
                                  ?.length -
                                  1 !==
                                  index && <div className="divider-line"></div>}
                              </div>
                            )
                          )
                        )}
                      </div>
                    </div>
                  )}

                  {/* VACATION BANNER */}
                  {this.state.shouldLoad && (
                    <div
                      className="vacation-banner-main"
                      style={{
                        backgroundImage: `url(${
                          this.state.isMobile ? mVacationImg : VacationImg
                        })`,
                      }}
                    >
                      <div className="content">
                        <div className="title">
                          Want to customise your <br />
                          vacation?
                        </div>
                        <div className="text">
                          Hyper customise your vacation with real-time price and{" "}
                          <br /> availability now
                        </div>
                        <br />
                        <br />
                        <Button
                          variant="contained"
                          onClick={() => this.handleSearchVacation()}
                        >
                          Customise my vacation
                        </Button>
                      </div>
                    </div>
                  )}

                  {/* OFFER BANNER */}
                  {/* <div className="offer-main">
                  <div className="offer-design-card">
                    <div className="text1">
                      Enjoy <span>10%</span> Off when you signup and
                      <br /> other exclusive offers
                    </div>
                    <div className="text2">
                      Use code <span>FIRSTBOOKING10</span>
                    </div>
                    <div className="text3">T&C apply*</div>
                    <a
                      href={void 0}
                      onClick={this.openPopup.bind(
                        this,
                        "#customer-signup-popup"
                      )}
                      className="button  cy-pack"
                    >
                      Signup now
                    </a>
                  </div>
                </div> */}

                  {this.state.shouldLoad && (
                    <div className="header-main">
                      <div className="divider"></div>
                      <div className="heading">Themes</div>
                      <div className="divider"></div>
                    </div>
                  )}

                  {this.state.shouldLoad && this.state.showHomePackages && (
                    <div className="package-trend">
                      <div>
                        {this.state.homePackageLoading === true ? (
                          <div className="search-loader">
                            {[...Array(this.state?.isMobile ? 1 : 2)].map(
                              (item, index) => (
                                <ContentLoader
                                  key={index}
                                  viewBox="0 0 380 280"
                                  height={280}
                                  width={380}
                                  speed={3}
                                >
                                  <rect
                                    x="3"
                                    y="3"
                                    rx="10"
                                    ry="10"
                                    width="380"
                                    height="180"
                                  />
                                  <rect
                                    x="6"
                                    y="190"
                                    rx="0"
                                    ry="0"
                                    width="380"
                                    height="20"
                                  />
                                  <rect
                                    x="4"
                                    y="215"
                                    rx="0"
                                    ry="0"
                                    width="320"
                                    height="20"
                                  />
                                  <rect
                                    x="4"
                                    y="242"
                                    rx="0"
                                    ry="0"
                                    width="380"
                                    height="20"
                                  />
                                </ContentLoader>
                              )
                            )}
                          </div>
                        ) : (
                          this.state.display_home_package["Themes"]?.map(
                            (segmentContent, index) => (
                              <div
                                className="main-content themes-main"
                                key={index}
                              >
                                <div className="title-with-description">
                                  <div className="title-col">
                                    <h2>{segmentContent.segmentName}</h2>
                                    <div className="package-discount">
                                      Enjoy 10% off* on your first booking Use
                                      code FIRSTBOOKING10
                                    </div>
                                  </div>
                                  <div className="action-btns">
                                    <div
                                      className="prevBtn"
                                      onClick={() =>
                                        segmentContent.carouselRef.current.prev()
                                      }
                                    >
                                      <i
                                        className="fa fa-angle-left"
                                        aria-hidden="true"
                                      ></i>
                                    </div>
                                    &nbsp;&nbsp;
                                    <div
                                      className="nextBtn"
                                      onClick={() =>
                                        segmentContent.carouselRef.current.next()
                                      }
                                    >
                                      <i
                                        className="fa fa-angle-right"
                                        aria-hidden="true"
                                      ></i>
                                    </div>
                                  </div>
                                  {/* <div
                                  className="view-more-link"
                                  onClick={() =>
                                    this.viewMore(segmentContent.segmentName)
                                  }
                                >
                                  View more <ArrowForwardIosIcon />
                                </div> */}
                                </div>{" "}
                                {/* Display segment name */}
                                <OwlCarousel
                                  key={`carousel-${index}`}
                                  ref={segmentContent.carouselRef} // Use the ref specific to this segment
                                  options={trendoption}
                                >
                                  {segmentContent.isLoading ? (
                                    <div className="search-loader">
                                      {[
                                        ...Array(this.state?.isMobile ? 1 : 3),
                                      ].map((item, index) => (
                                        <ContentLoader
                                          key={index}
                                          viewBox="0 0 380 280"
                                          height={280}
                                          width={380}
                                          speed={3}
                                        >
                                          <rect
                                            x="3"
                                            y="3"
                                            rx="10"
                                            ry="10"
                                            width="380"
                                            height="180"
                                          />
                                          <rect
                                            x="6"
                                            y="190"
                                            rx="0"
                                            ry="0"
                                            width="380"
                                            height="20"
                                          />
                                          <rect
                                            x="4"
                                            y="215"
                                            rx="0"
                                            ry="0"
                                            width="320"
                                            height="20"
                                          />
                                          <rect
                                            x="4"
                                            y="242"
                                            rx="0"
                                            ry="0"
                                            width="380"
                                            height="20"
                                          />
                                        </ContentLoader>
                                      ))}
                                    </div>
                                  ) : (
                                    segmentContent.packages
                                  )}
                                </OwlCarousel>
                              </div>
                            )
                          )
                        )}
                      </div>
                    </div>
                  )}

                  {/* VACATION BANNER */}
                  {this.state.shouldLoad && (
                    <div
                      className="vacation-banner-main1"
                      style={{
                        backgroundImage: `url(${
                          this.state.isMobile ? mCuratedImg : CuratedImg
                        })`,
                      }}
                    >
                      <div className="content">
                        <div className="title">
                          Curated vacation packages
                          <br /> from 120+ local
                          <br /> travel providers
                        </div>
                        <div className="text">
                          Top hand crafted vacation packages from the top local{" "}
                          <br />
                          travel experts for you. 24/7 instant support available
                        </div>
                        <br />
                        <br />
                        <div className="img-group">
                          <AvatarGroup>
                            <Avatar
                              alt="Mouni"
                              src="https://images.fizota.com/Mouni.webp"
                            />
                            <Avatar
                              alt="Nilofar"
                              src="https://images.fizota.com/Nilofar.webp"
                            />
                            <Avatar
                              alt="Gowtham"
                              src="https://images.fizota.com/Gowtham.webp"
                            />
                            <Avatar
                              alt="Bose"
                              src="https://images.fizota.com/Bose.webp"
                            />
                          </AvatarGroup>
                          <Button
                            variant="contained"
                            onClick={() => this.handleWhatsAppLink()}
                          >
                            Chat us with our expert
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.shouldLoad && (
                    <div className="header-main">
                      <div className="divider"></div>
                      <div className="heading">Curated</div>
                      <div className="divider"></div>
                    </div>
                  )}
                  {this.state.shouldLoad && this.state.showHomePackages && (
                    <div className="package-trend">
                      <div>
                        {this.state.homePackageLoading === true ? (
                          <div className="search-loader">
                            {[...Array(this.state?.isMobile ? 1 : 2)].map(
                              (item, index) => (
                                <ContentLoader
                                  key={index}
                                  viewBox="0 0 380 280"
                                  height={280}
                                  width={380}
                                  speed={3}
                                >
                                  <rect
                                    x="3"
                                    y="3"
                                    rx="10"
                                    ry="10"
                                    width="380"
                                    height="180"
                                  />
                                  <rect
                                    x="6"
                                    y="190"
                                    rx="0"
                                    ry="0"
                                    width="380"
                                    height="20"
                                  />
                                  <rect
                                    x="4"
                                    y="215"
                                    rx="0"
                                    ry="0"
                                    width="320"
                                    height="20"
                                  />
                                  <rect
                                    x="4"
                                    y="242"
                                    rx="0"
                                    ry="0"
                                    width="380"
                                    height="20"
                                  />
                                </ContentLoader>
                              )
                            )}
                          </div>
                        ) : (
                          this.state.display_home_package["Curated"]?.map(
                            (segmentContent, index) => (
                              <div
                                className="main-content curated-main"
                                key={index}
                              >
                                <div className="title-with-description">
                                  <div className="title-col">
                                    <h2>{segmentContent.segmentName}</h2>
                                    <div className="package-discount">
                                      Enjoy 10% off* on your first booking Use
                                      code FIRSTBOOKING10
                                    </div>
                                  </div>
                                  <div className="action-btns">
                                    <div
                                      className="prevBtn"
                                      onClick={() =>
                                        segmentContent.carouselRef.current.prev()
                                      }
                                    >
                                      <i
                                        className="fa fa-angle-left"
                                        aria-hidden="true"
                                      ></i>
                                    </div>
                                    &nbsp;&nbsp;
                                    <div
                                      className="nextBtn"
                                      onClick={() =>
                                        segmentContent.carouselRef.current.next()
                                      }
                                    >
                                      <i
                                        className="fa fa-angle-right"
                                        aria-hidden="true"
                                      ></i>
                                    </div>
                                  </div>
                                </div>{" "}
                                {/* Display segment name */}
                                <OwlCarousel
                                  key={`carousel-${index}`}
                                  ref={segmentContent.carouselRef} // Use the ref specific to this segment
                                  options={trendoption}
                                >
                                  {segmentContent.isLoading ? (
                                    <div className="search-loader">
                                      {[
                                        ...Array(this.state?.isMobile ? 1 : 3),
                                      ].map((item, index) => (
                                        <ContentLoader
                                          key={index}
                                          viewBox="0 0 380 280"
                                          height={280}
                                          width={380}
                                          speed={3}
                                        >
                                          <rect
                                            x="3"
                                            y="3"
                                            rx="10"
                                            ry="10"
                                            width="380"
                                            height="180"
                                          />
                                          <rect
                                            x="6"
                                            y="190"
                                            rx="0"
                                            ry="0"
                                            width="380"
                                            height="20"
                                          />
                                          <rect
                                            x="4"
                                            y="215"
                                            rx="0"
                                            ry="0"
                                            width="320"
                                            height="20"
                                          />
                                          <rect
                                            x="4"
                                            y="242"
                                            rx="0"
                                            ry="0"
                                            width="380"
                                            height="20"
                                          />
                                        </ContentLoader>
                                      ))}
                                    </div>
                                  ) : (
                                    segmentContent.packages
                                  )}
                                </OwlCarousel>
                              </div>
                            )
                          )
                        )}
                      </div>
                    </div>
                  )}

                  {/* DESTINATION LIST */}
                  {this.state.shouldLoad && (
                    <div className="destination-list-main">
                      <div className="title">
                        Discover and Explore vacation from 50+ Destination
                      </div>
                      <div className="row">
                        {Object.keys(this.state.cityList).length > 0 &&
                          Object.keys(this.state.cityList)?.map((item, i) => {
                            if (i === 0) {
                              count = 0;
                            }
                            var cityList = this.state.cityList[item];
                            if (cityList.city.length > 0) {
                              return cityList.city.map((citem, cindex) => {
                                if (citem.package_available === "Y") {
                                  count++;
                                  return (
                                    <>
                                      {count === 16 && this.state.isMobile && (
                                        <br />
                                      )}
                                      <button>
                                        <div
                                          className="location-row"
                                          onClick={() => {
                                            this.selectCountryPackages(
                                              cityList.country,
                                              citem.city_name
                                            );
                                          }}
                                        >
                                          <div className="col1">
                                            <img src={citem?.thumbnail} />
                                          </div>
                                          <div className="col2">
                                            <div
                                              className="location"
                                              key={cindex}
                                            >
                                            {citem.country_name}
                                            </div>
                                            <div className="country">
                                                {citem.city_name}
                                            </div>
                                          </div>
                                        </div>
                                      </button>
                                    </>
                                  );
                                }
                              });
                            }
                          })}
                      </div>
                    </div>
                  )}
                  {this.state.shouldLoad && (
                    <div className="company-banner">
                      <div className="text">
                        Curated vacation from <span>120+</span>
                        <br /> expert travel providers
                      </div>
                      <div>
                        <img src={companyGif} />
                      </div>
                    </div>
                  )}
                  {this.state.shouldLoad && (
                    <div className="reviews-main">
                      <div className="col1">
                        <div className="text">Memories shared</div>
                        <div className="text1">Travelers tales</div>
                        <img src={commaReview} />
                      </div>
                      <div className="col2">
                        {this.state.reviews?.map((item, index) => (
                          <div className="review-row" key={index}>
                            <div className="col3">
                              <img src={item?.customer_photo} />
                            </div>
                            <div className="col4">
                              <div className="name">
                                {item?.customer_name}{" "}
                                <div className="dot"></div> {item?.country}
                                <div className="dot"></div>{" "}
                                {item?.customer_rating && (
                                  <div className="star-rating">
                                    {item?.customer_rating}{" "}
                                    {this.loadRatingstar(item?.customer_rating)}
                                  </div>
                                )}
                              </div>
                              <div className="review">{item?.description}</div>
                              {item?.image_gallery?.length && (
                                <AvatarGroup max={this.state.isMobile ? 6 : 8}>
                                  {item?.image_gallery?.map((a, i) => (
                                    <Avatar alt="pic" src={a} key={i} />
                                  ))}
                                </AvatarGroup>
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </>
              ) : this.state.selectedTab === "flight" ? (
                <>
                  <div className="header-main">
                    <div className="divider"></div>
                    <div className="heading">Popular flight routes</div>
                    <div className="divider"></div>
                  </div>

                  <div className="flight-routes-main">
                    <div className="content">
                      {this.state.flightRoutes.map((item, index) => (
                        <div
                          className="row"
                          key={index}
                          onClick={() => this.handleSearchFlight(item)}
                        >
                          <div className="col1">
                            <img src={item?.to_flight_image} />
                          </div>
                          <div className="col2">
                            <div className="from">
                              {item?.flight_route?.split("-")[0]}{" "}
                              <img src={FlightsSeprate} />
                            </div>
                            <div className="to">
                              {item?.flight_route?.split("-")[1]}
                            </div>
                            <div className="price">
                              Starts from{" "}
                              <span>
                                {showPriceValue(
                                  item?.flight_min_price,
                                  this.state.currentCurrency,
                                  this.state.currencyRate
                                )}
                              </span>
                            </div>
                          </div>
                          {/* <div className="col3">
                          <div className="discount">$255 Off*</div>
                        </div> */}
                        </div>
                      ))}
                    </div>
                  </div>
                  {this.state.shouldLoad && (
                    <>
                      <div className="header-main">
                        <div className="divider"></div>
                        <div className="heading">
                          Flights for Popular Destinations
                        </div>
                        <div className="divider"></div>
                      </div>

                      {Object.keys(this.state.popularDestinations)?.length ? (
                        <div className="package-trend">
                          <div className="popular-destination">
                            {this.state.homePackageLoading === true ? (
                              <div className="search-loader">
                                {[...Array(this.state?.isMobile ? 1 : 4)].map(
                                  (item, index) => (
                                    <ContentLoader
                                      key={index}
                                      viewBox="0 0 380 280"
                                      height={280}
                                      width={380}
                                      speed={3}
                                    >
                                      <rect
                                        x="3"
                                        y="3"
                                        rx="10"
                                        ry="10"
                                        width="380"
                                        height="180"
                                      />
                                      <rect
                                        x="6"
                                        y="190"
                                        rx="0"
                                        ry="0"
                                        width="380"
                                        height="20"
                                      />
                                      <rect
                                        x="4"
                                        y="215"
                                        rx="0"
                                        ry="0"
                                        width="320"
                                        height="20"
                                      />
                                      <rect
                                        x="4"
                                        y="242"
                                        rx="0"
                                        ry="0"
                                        width="380"
                                        height="20"
                                      />
                                    </ContentLoader>
                                  )
                                )}
                              </div>
                            ) : (
                              Object.keys(this.state.popularDestinations)?.map(
                                (segmentKey, index) => (
                                  <div className="main-content" key={index}>
                                    <div className="title-with-description">
                                      <div className="title-col">
                                        {index === 0 && <h2>Flight from</h2>}
                                        <span>{segmentKey}</span>
                                      </div>
                                      <div className="action-btns">
                                        <div
                                          className="prevBtn"
                                          onClick={() =>
                                            this.state.popularDestinations[
                                              segmentKey
                                            ].carouselRef.current.prev()
                                          }
                                        >
                                          <i
                                            className="fa fa-angle-left"
                                            aria-hidden="true"
                                          ></i>
                                        </div>
                                        &nbsp;&nbsp;
                                        <div
                                          className="nextBtn"
                                          onClick={() =>
                                            this.state.popularDestinations[
                                              segmentKey
                                            ].carouselRef.current.next()
                                          }
                                        >
                                          <i
                                            className="fa fa-angle-right"
                                            aria-hidden="true"
                                          ></i>
                                        </div>
                                      </div>
                                    </div>{" "}
                                    {/* Display segment name */}
                                    <OwlCarousel
                                      key={`carousel-${index}`}
                                      ref={
                                        this.state.popularDestinations[
                                          segmentKey
                                        ].carouselRef
                                      } // Use the ref specific to this segment
                                      options={trendoption}
                                    >
                                      {this.state.popularDestinations[
                                        segmentKey
                                      ].isLoading ? (
                                        <div className="search-loader">
                                          {[
                                            ...Array(
                                              this.state?.isMobile ? 1 : 3
                                            ),
                                          ].map((item, index) => (
                                            <ContentLoader
                                              key={index}
                                              viewBox="0 0 380 280"
                                              height={280}
                                              width={380}
                                              speed={3}
                                            >
                                              <rect
                                                x="3"
                                                y="3"
                                                rx="10"
                                                ry="10"
                                                width="380"
                                                height="180"
                                              />
                                              <rect
                                                x="6"
                                                y="190"
                                                rx="0"
                                                ry="0"
                                                width="380"
                                                height="20"
                                              />
                                              <rect
                                                x="4"
                                                y="215"
                                                rx="0"
                                                ry="0"
                                                width="320"
                                                height="20"
                                              />
                                              <rect
                                                x="4"
                                                y="242"
                                                rx="0"
                                                ry="0"
                                                width="380"
                                                height="20"
                                              />
                                            </ContentLoader>
                                          ))}
                                        </div>
                                      ) : (
                                        this.state.popularDestinations[
                                          segmentKey
                                        ].packages?.map((item, index) => (
                                          <div
                                            key={index}
                                            className="card-main"
                                            style={{
                                              backgroundImage: `url(${item?.to_flight_image})`,
                                            }}
                                            onClick={() =>
                                              this.handleSearchFlight(item)
                                            }
                                          >
                                            <div className="content">
                                              <div className="from">
                                                {
                                                  item?.flight_route?.split(
                                                    "-"
                                                  )[0]
                                                }
                                              </div>
                                              <div className="to">
                                                {
                                                  item?.flight_route?.split(
                                                    "-"
                                                  )[1]
                                                }
                                              </div>
                                              <div className="price">
                                                Start from{" "}
                                                {showPriceValue(
                                                  item?.flight_min_price,
                                                  this.state.currentCurrency,
                                                  this.state.currencyRate
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        ))
                                      )}
                                    </OwlCarousel>
                                    {Object.keys(this.state.popularDestinations)
                                      ?.length -
                                      1 !==
                                      index && (
                                      <>
                                        <br />
                                        <br />
                                        <div className="divider-line"></div>
                                      </>
                                    )}
                                  </div>
                                )
                              )
                            )}
                          </div>
                        </div>
                      ) : null}
                    </>
                  )}
                  {/* VACATION BANNER */}
                  {this.state.shouldLoad && (
                    <div
                      className="vacation-banner-main1"
                      style={{
                        backgroundImage: `url(${
                          this.state.isMobile ? mFlightsImg : flightsImg
                        })`,
                      }}
                    >
                      <div
                        className="content"
                        style={{
                          background:
                            "transparent linear-gradient(270deg, #59595900 0%, #5392A2BE 57%, #438B9CDA 81%, #4495A9 100%) 0% 0% no-repeat padding-box",
                        }}
                      >
                        <div className="title">
                          Book flight tickets from 600+
                          <br /> airlines across the globe at
                          <br /> best price
                        </div>
                        <div className="text">
                          24/7 instant support available.
                        </div>
                        <br />
                        <br />
                        <div className="img-group">
                          <AvatarGroup>
                            <Avatar
                              alt="Mouni"
                              src="https://images.fizota.com/Mouni.webp"
                            />
                            <Avatar
                              alt="Nilofar"
                              src="https://images.fizota.com/Nilofar.webp"
                            />
                            <Avatar
                              alt="Gowtham"
                              src="https://images.fizota.com/Gowtham.webp"
                            />
                            <Avatar
                              alt="Bose"
                              src="https://images.fizota.com/Bose.webp"
                            />
                          </AvatarGroup>
                          <Button
                            variant="contained"
                            onClick={() => this.handleWhatsAppLink()}
                          >
                            Chat us with our expert
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ) : this.state.selectedTab === "ttd" ? (
                <>
                  <div className="package-trend">
                    <div className="recommend-activities">
                      {this.state.homePackageLoading === true ? (
                        <div className="search-loader">
                          {[...Array(this.state?.isMobile ? 1 : 2)].map(
                            (item, index) => (
                              <ContentLoader
                                key={index}
                                viewBox="0 0 380 280"
                                height={280}
                                width={380}
                                speed={3}
                              >
                                <rect
                                  x="3"
                                  y="3"
                                  rx="10"
                                  ry="10"
                                  width="380"
                                  height="180"
                                />
                                <rect
                                  x="6"
                                  y="190"
                                  rx="0"
                                  ry="0"
                                  width="380"
                                  height="20"
                                />
                                <rect
                                  x="4"
                                  y="215"
                                  rx="0"
                                  ry="0"
                                  width="320"
                                  height="20"
                                />
                                <rect
                                  x="4"
                                  y="242"
                                  rx="0"
                                  ry="0"
                                  width="380"
                                  height="20"
                                />
                              </ContentLoader>
                            )
                          )}
                        </div>
                      ) : (
                        this.state.display_ttd_package["Recommended"]?.map(
                          (segmentContent, index) => (
                            <div className="main-content" key={index}>
                              <div className="title-with-description">
                                <div className="title-col">
                                  <h2>{segmentContent?.segmentName}</h2>
                                </div>
                                <div className="action-btns">
                                  <div
                                    className="prevBtn"
                                    onClick={() =>
                                      segmentContent.carouselRef.current.prev()
                                    }
                                  >
                                    <i
                                      className="fa fa-angle-left"
                                      aria-hidden="true"
                                    ></i>
                                  </div>
                                  &nbsp;&nbsp;
                                  <div
                                    className="nextBtn"
                                    onClick={() =>
                                      segmentContent.carouselRef.current.next()
                                    }
                                  >
                                    <i
                                      className="fa fa-angle-right"
                                      aria-hidden="true"
                                    ></i>
                                  </div>
                                </div>
                              </div>{" "}
                              {/* Display segment name */}
                              <OwlCarousel
                                key={`carousel-${index}`}
                                ref={segmentContent.carouselRef} // Use the ref specific to this segment
                                options={trendoption}
                              >
                                {segmentContent.isLoading ? (
                                  <div className="search-loader">
                                    {[
                                      ...Array(this.state?.isMobile ? 1 : 3),
                                    ].map((item, index) => (
                                      <ContentLoader
                                        key={index}
                                        viewBox="0 0 380 280"
                                        height={280}
                                        width={380}
                                        speed={3}
                                      >
                                        <rect
                                          x="3"
                                          y="3"
                                          rx="10"
                                          ry="10"
                                          width="380"
                                          height="180"
                                        />
                                        <rect
                                          x="6"
                                          y="190"
                                          rx="0"
                                          ry="0"
                                          width="380"
                                          height="20"
                                        />
                                        <rect
                                          x="4"
                                          y="215"
                                          rx="0"
                                          ry="0"
                                          width="320"
                                          height="20"
                                        />
                                        <rect
                                          x="4"
                                          y="242"
                                          rx="0"
                                          ry="0"
                                          width="380"
                                          height="20"
                                        />
                                      </ContentLoader>
                                    ))}
                                  </div>
                                ) : (
                                  segmentContent.packages
                                )}
                              </OwlCarousel>
                            </div>
                          )
                        )
                      )}
                    </div>
                  </div>

                  {this.state.shouldLoad && this.state.ttdCityData?.length ? (
                    <div className="explr-dest">
                      <div className="title-with-description">
                        <div className="title-col">
                          <h2>Explore popular destinations</h2>
                        </div>
                        <div className="action-btns">
                          <div
                            className="prevBtn"
                            onClick={() => this.carouselRef.current.prev()}
                          >
                            <i
                              className="fa fa-angle-left"
                              aria-hidden="true"
                            ></i>
                          </div>
                          &nbsp;&nbsp;
                          <div
                            className="nextBtn"
                            onClick={() => this.carouselRef.current.next()}
                          >
                            <i
                              className="fa fa-angle-right"
                              aria-hidden="true"
                            ></i>
                          </div>
                        </div>
                      </div>

                      <div className="main-content">
                        <OwlCarousel
                          ref={this.carouselRef}
                          options={destoption}
                        >
                          {this.state.ttdCityData?.map((item, index) => (
                            <div key={index}>
                              <a
                                href={void 0}
                                onClick={() =>
                                  this.selectProduct("country", item)
                                }
                                className="cover-parent"
                              >
                                <div className="curate-img">
                                  <img
                                    src={item?.images}
                                    alt={"singapore"}
                                    loading="lazy"
                                  />
                                </div>
                                <div className="curate-def">
                                  <div className="city">
                                    <FmdGoodIcon className="loc-icon" />{" "}
                                    {item?.city_name}
                                  </div>
                                  <span className="price">
                                    {item?.total_records}+ Things to do
                                    available
                                  </span>
                                </div>
                              </a>
                            </div>
                          ))}
                        </OwlCarousel>
                      </div>
                    </div>
                  ) : null}

                  {this.state.shouldLoad &&
                  Object.keys(this.state.display_ttd_package)?.length ? (
                    <div className="package-trend">
                      <div className="popular-destination">
                        {this.state.homePackageLoading === true ? (
                          <div className="search-loader">
                            {[...Array(this.state?.isMobile ? 1 : 4)].map(
                              (item, index) => (
                                <ContentLoader
                                  key={index}
                                  viewBox="0 0 380 280"
                                  height={280}
                                  width={380}
                                  speed={3}
                                >
                                  <rect
                                    x="3"
                                    y="3"
                                    rx="10"
                                    ry="10"
                                    width="380"
                                    height="180"
                                  />
                                  <rect
                                    x="6"
                                    y="190"
                                    rx="0"
                                    ry="0"
                                    width="380"
                                    height="20"
                                  />
                                  <rect
                                    x="4"
                                    y="215"
                                    rx="0"
                                    ry="0"
                                    width="320"
                                    height="20"
                                  />
                                  <rect
                                    x="4"
                                    y="242"
                                    rx="0"
                                    ry="0"
                                    width="380"
                                    height="20"
                                  />
                                </ContentLoader>
                              )
                            )}
                          </div>
                        ) : (
                          this.state.display_ttd_package["Top 10"]?.map(
                            (segment, index) => (
                              <div className="main-content" key={index}>
                                <div className="title-with-description">
                                  <div className="title-col">
                                    {index === 0 && (
                                      <h2>Top 10 things to do</h2>
                                    )}
                                    <span>{segment?.segmentName}</span>
                                  </div>
                                  <div className="action-btns">
                                    <div
                                      className="prevBtn"
                                      onClick={() =>
                                        segment.carouselRef.current.prev()
                                      }
                                    >
                                      <i
                                        className="fa fa-angle-left"
                                        aria-hidden="true"
                                      ></i>
                                    </div>
                                    &nbsp;&nbsp;
                                    <div
                                      className="nextBtn"
                                      onClick={() =>
                                        segment.carouselRef.current.next()
                                      }
                                    >
                                      <i
                                        className="fa fa-angle-right"
                                        aria-hidden="true"
                                      ></i>
                                    </div>
                                  </div>
                                </div>{" "}
                                {/* Display segment name */}
                                <OwlCarousel
                                  key={`carousel-${index}`}
                                  ref={segment.carouselRef} // Use the ref specific to this segment
                                  options={trendoption}
                                >
                                  {segment.isLoading ? (
                                    <div className="search-loader">
                                      {[
                                        ...Array(this.state?.isMobile ? 1 : 3),
                                      ].map((item, index) => (
                                        <ContentLoader
                                          key={index}
                                          viewBox="0 0 380 280"
                                          height={280}
                                          width={380}
                                          speed={3}
                                        >
                                          <rect
                                            x="3"
                                            y="3"
                                            rx="10"
                                            ry="10"
                                            width="380"
                                            height="180"
                                          />
                                          <rect
                                            x="6"
                                            y="190"
                                            rx="0"
                                            ry="0"
                                            width="380"
                                            height="20"
                                          />
                                          <rect
                                            x="4"
                                            y="215"
                                            rx="0"
                                            ry="0"
                                            width="320"
                                            height="20"
                                          />
                                          <rect
                                            x="4"
                                            y="242"
                                            rx="0"
                                            ry="0"
                                            width="380"
                                            height="20"
                                          />
                                        </ContentLoader>
                                      ))}
                                    </div>
                                  ) : (
                                    segment.packages?.map((item, index) => (
                                      <div
                                        key={index}
                                        className="card-main"
                                        style={{
                                          backgroundImage: `url(${apiglobaltixMediaUrl}${item?.thumbnail})`,
                                        }}
                                        onClick={() =>
                                          this.selectProduct("product", item)
                                        }
                                      >
                                        <div className="content">
                                          <div className="from">
                                            <FmdGoodIcon className="loc-icon" />{" "}
                                            {item?.city}
                                          </div>
                                          <div className="to">
                                            {item?.product_name}
                                          </div>
                                          <div className="price">
                                            Start from{" "}
                                            {showPriceValue(
                                              item?.price,
                                              this.state.currentCurrency,
                                              this.state.currencyRate
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    ))
                                  )}
                                </OwlCarousel>
                                {this.state.display_ttd_package["Top 10"]
                                  ?.length -
                                  1 !==
                                  index && (
                                  <>
                                    <br />
                                    <br />
                                    <div className="divider-line"></div>
                                  </>
                                )}
                              </div>
                            )
                          )
                        )}
                      </div>
                    </div>
                  ) : null}

                  {/* VACATION BANNER */}
                  {this.state.shouldLoad && (
                    <div
                      className="vacation-banner-main1"
                      style={{
                        backgroundImage: `url(${
                          this.state.isMobile ? mTtdImg : ttdImg
                        })`,
                      }}
                    >
                      <div
                        className="content"
                        style={{
                          background:
                            "transparent linear-gradient(270deg, #59595900 0%, #6D668284 0%, #776D97C8 30%, #716597 62%) 0% 0% no-repeat padding-box",
                        }}
                      >
                        <div className="title">
                          Discover 100k+ fun
                          <br /> attractions & activities from
                          <br /> 30+ destinations
                        </div>
                        <div className="text">
                          Top things to do, attractions, activities for you.
                          24/7
                          <br /> instant support available
                        </div>
                        <br />
                        <br />
                        <div className="img-group">
                          <AvatarGroup>
                            <Avatar
                              alt="Mouni"
                              src="https://images.fizota.com/Mouni.webp"
                            />
                            <Avatar
                              alt="Nilofar"
                              src="https://images.fizota.com/Nilofar.webp"
                            />
                            <Avatar
                              alt="Gowtham"
                              src="https://images.fizota.com/Gowtham.webp"
                            />
                            <Avatar
                              alt="Bose"
                              src="https://images.fizota.com/Bose.webp"
                            />
                          </AvatarGroup>
                          <Button
                            variant="contained"
                            onClick={() => this.handleWhatsAppLink()}
                          >
                            Chat us with our expert
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.shouldLoad && (
                    <div className="header-main">
                      <div className="divider"></div>
                      <div className="heading">Themes</div>
                      <div className="divider"></div>
                    </div>
                  )}

                  <div className="package-trend">
                    <div className="activity-themes">
                      {this.state.shouldLoad &&
                      this.state.homePackageLoading === true ? (
                        <div className="search-loader">
                          {[...Array(this.state?.isMobile ? 1 : 2)].map(
                            (item, index) => (
                              <ContentLoader
                                key={index}
                                viewBox="0 0 380 280"
                                height={280}
                                width={380}
                                speed={3}
                              >
                                <rect
                                  x="3"
                                  y="3"
                                  rx="10"
                                  ry="10"
                                  width="380"
                                  height="180"
                                />
                                <rect
                                  x="6"
                                  y="190"
                                  rx="0"
                                  ry="0"
                                  width="380"
                                  height="20"
                                />
                                <rect
                                  x="4"
                                  y="215"
                                  rx="0"
                                  ry="0"
                                  width="320"
                                  height="20"
                                />
                                <rect
                                  x="4"
                                  y="242"
                                  rx="0"
                                  ry="0"
                                  width="380"
                                  height="20"
                                />
                              </ContentLoader>
                            )
                          )}
                        </div>
                      ) : (
                        this.state.display_ttd_package["Themes"]?.map(
                          (segmentContent, index) => (
                            <div
                              className="main-content themes-main"
                              key={index}
                            >
                              <div className="title-with-description">
                                <div className="title-col">
                                  <h2>{segmentContent?.segmentName}</h2>
                                  <div className="package-discount">
                                    {
                                      this.state.display_ttd_package[
                                        "themes_banner"
                                      ]
                                    }
                                  </div>
                                </div>
                                <div className="action-btns">
                                  <div
                                    className="prevBtn"
                                    onClick={() =>
                                      segmentContent.carouselRef.current.prev()
                                    }
                                  >
                                    <i
                                      className="fa fa-angle-left"
                                      aria-hidden="true"
                                    ></i>
                                  </div>
                                  &nbsp;&nbsp;
                                  <div
                                    className="nextBtn"
                                    onClick={() =>
                                      segmentContent.carouselRef.current.next()
                                    }
                                  >
                                    <i
                                      className="fa fa-angle-right"
                                      aria-hidden="true"
                                    ></i>
                                  </div>
                                </div>
                              </div>{" "}
                              {/* Display segment name */}
                              <OwlCarousel
                                key={`carousel-${index}`}
                                ref={segmentContent.carouselRef} // Use the ref specific to this segment
                                options={trendoption}
                              >
                                {segmentContent.isLoading ? (
                                  <div className="search-loader">
                                    {[
                                      ...Array(this.state?.isMobile ? 1 : 3),
                                    ].map((item, index) => (
                                      <ContentLoader
                                        key={index}
                                        viewBox="0 0 380 280"
                                        height={280}
                                        width={380}
                                        speed={3}
                                      >
                                        <rect
                                          x="3"
                                          y="3"
                                          rx="10"
                                          ry="10"
                                          width="380"
                                          height="180"
                                        />
                                        <rect
                                          x="6"
                                          y="190"
                                          rx="0"
                                          ry="0"
                                          width="380"
                                          height="20"
                                        />
                                        <rect
                                          x="4"
                                          y="215"
                                          rx="0"
                                          ry="0"
                                          width="320"
                                          height="20"
                                        />
                                        <rect
                                          x="4"
                                          y="242"
                                          rx="0"
                                          ry="0"
                                          width="380"
                                          height="20"
                                        />
                                      </ContentLoader>
                                    ))}
                                  </div>
                                ) : (
                                  segmentContent.packages
                                )}
                              </OwlCarousel>
                            </div>
                          )
                        )
                      )}
                    </div>
                  </div>
                </>
              ) : null}

              {/* <div className="platform-step">
                <h1 className="color-header textcenter">
                  <span> One</span> platform, <span>Endless</span> options
                </h1>
                <ul>
                  <li>
                    <figure>
                      <img src={step1} alt="step1" />
                    </figure>
                    <h5>Step 1</h5>
                    <p>
                      Get inspired by the pre-built packages by the community or
                      Craft your own package.
                    </p>
                  </li>
                  <li>
                    <figure>
                      <img src={step2} alt="step2" />
                    </figure>
                    <h5>Step 2</h5>
                    <p>
                      Choose your travel needs from the wider inventory and get
                      the real-time prices.
                    </p>
                  </li>
                  <li>
                    <figure>
                      <img src={step3} alt="step3" />
                    </figure>
                    <h5>Step 3</h5>
                    <p>
                      Book all your travel itinerary with a single click. Redeem
                      with a single ticket.
                    </p>
                  </li>
                </ul>
                <div className="step-slide">
                  <OwlCarousel options={mobilesteps}>
                    <div>
                      <figure>
                        <img src={step1} alt="step1" />
                      </figure>
                      <h5>Step 1</h5>
                      <p>
                        Get inspired by the pre-built packages by the community
                        or Craft your own package.
                      </p>
                    </div>
                    <div>
                      <figure>
                        <img src={step2} alt="step2" />
                      </figure>
                      <h5>Step 2</h5>
                      <p>
                        Choose your travel needs from the wider inventory and
                        get the real-time prices.
                      </p>
                    </div>
                    <div>
                      <figure>
                        <img src={step3} alt="step3" />
                      </figure>
                      <h5>Step 3</h5>
                      <p>
                        Book all your travel itinerary with a single click.
                        Redeem with a single ticket.
                      </p>
                    </div>
                  </OwlCarousel>
                </div>
                <div className="center-btn textcenter">
                  <a href={void 0} className="button">
                    Search your dream vacation
                  </a>
                </div>
              </div> */}
            </div>
          </section>

          {this.state.shouldLoad && !this.state.isMobile && (
            <section className="home-vacation-mid">
              <div className="container">
                <div className="vacation-box-btm rel">
                  <div className="vacation-box-txt">
                    <h2>Become a partner and start earning. </h2>
                    <p>
                      Signup for free and start designing vacation packages.
                    </p>
                    <Link to="/creator" className="button ghost-button">
                      Get started
                    </Link>
                  </div>
                  <div className="vacation-box-img">
                    <img src={cabox} alt="vacation" />
                  </div>
                </div>
              </div>
            </section>
          )}
          {this.state.shouldLoad && (
            <section className="home-newsletter textcenter">
              <div className="container">
                <div className="formboy">
                  <h2>
                    Join the community and get <span>inspired.</span>
                  </h2>
                  <p>
                    Subscribe and get the weekly inspirational <br />
                    content right at your inbox.
                  </p>
                  <div className="newsletter-form">
                    <input
                      type="email"
                      id="email"
                      className="subscripe_email"
                      placeholder="Enter your email"
                      required=""
                      name="subMail"
                      value={this.state.subMail}
                      onChange={this.handleNameChange}
                    />
                    <button
                      type="submit"
                      className={
                        this.state.emailvalid === false
                          ? "button"
                          : "button debutton"
                      }
                      onClick={this.handleSubmit}
                    >
                      Subscribe
                    </button>
                    <h4 className="rel">{this.state.submsg}</h4>
                  </div>
                </div>
              </div>
              <div id="customer-signup-popup" className="mfp-hide common-popup">
                <SignupComponent />
              </div>
              <div
                id="destination-popup"
                className="mfp-hide common-popup destination-popup"
              >
                <div className="destin-title">
                  <h3 className="color-header">
                    Where would you like to <span>go?</span>
                  </h3>
                  <div className="search-pop">
                    <input
                      type="text"
                      placeholder="Search your dream destination.."
                      onChange={this.handleTextChange.bind(this)}
                    />
                    <img src={mobsearch} alt="search-icon" />
                  </div>
                </div>
                {this.state.searchCountryList.length > 0 && (
                  <div className="destin-reult-show">
                    <div className="force-overflow">
                      {this.state.searchCountryList.map((item, index) => {
                        return (
                          <div className="drs-parent" key={index}>
                            {this.state.searchCityList.length > 0 && (
                              <ul>
                                {this.state.searchCityList.map(
                                  (city, cityIndex) => {
                                    if (city.country_name === item) {
                                      return (
                                        <li key={cityIndex}>
                                          <a
                                            href={void 0}
                                            onClick={() => {
                                              if (this.state.isMobile) {
                                                this.props.openBottomSheet(
                                                  "dest"
                                                );
                                                this.selectNewDestination(city);
                                              } else
                                                this.selectNewDestination(city);
                                            }}
                                          >
                                            <img
                                              src={
                                                (city.thumbnail !== "") &
                                                (city.thumbnail !== null)
                                                  ? city.thumbnail
                                                  : avplace
                                              }
                                            />
                                            <span>{city.city_name}</span>
                                          </a>
                                        </li>
                                      );
                                    }
                                  }
                                )}
                              </ul>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>

              {this.state.Errorpop && (
                <div>
                  <div
                    id="earning-details"
                    className="mfp-hide common-popup earnpop-details"
                  >
                    <div className="ed-header">
                      <h3>{this.state.submsg}</h3>
                    </div>
                    <div className="ed-body">
                      <button onClick={() => this.closePopup()}>Ok</button>
                    </div>
                  </div>
                </div>
              )}
            </section>
          )}
          <div
            id="calendar-date-popup"
            className="mfp-hide common-popup destination-popup"
          >
            <div className="date-popup-menu">
              <div className="step-content-calender textcenter searchContainer__bottomSheetMain__container__date">
                <div className="title">Select dates</div>
                <DateRange
                  showDateDisplay={false}
                  startDatePlaceholder={"Start Date"}
                  endDatePlaceholder={"End Date"}
                  editableDateInputs={false}
                  moveRangeOnFirstSelection={false}
                  ranges={[this.state.selectionRange]}
                  onChange={this.handleSelectDateRangeSelectRange.bind(this)}
                  rangeColors={["#4258bf"]}
                  months={this.state.isMobile ? 12 : 2}
                  minDate={new Date()}
                  direction={this.state.isMobile ? "vertical" : "horizontal"}
                  preventSnapRefocus
                />
                <div className="continue-btn">
                  <a
                    href={void 0}
                    className="button confirm-button"
                    onClick={() => this.closeDatePopup()}
                  >
                    Continue
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            id="reset-password-popup"
            className="white-popup mfp-hide popup_sec resetpw_popup changepw_popup common-popup"
          >
            <div className="pouup_in">
              <h3 className="title1 text-center">Reset Password</h3>

              <div className="popup-body">
                <span className="spn-error alert alert_success"></span>

                <div className="form-group">
                  <div className="focus-out pwd-group">
                    <div className="pwd-group">
                      <input
                        type={this.state.passwordType}
                        placeholder="New Password"
                        minlegth="8"
                        className="form-control input-focus"
                        onChange={this.handleChange.bind(this, "new_password")}
                      />
                      {this.state.new_passwordError !== "" && (
                        <div className="error">
                          {this.state.new_passwordError}
                        </div>
                      )}
                      <span
                        className={
                          this.state.passwordType === "text" ? "active" : ""
                        }
                      >
                        <i
                          className="fa fa-eye-slash"
                          aria-hidden="true"
                          onClick={this.showhidePWD.bind(this)}
                        ></i>
                        <i
                          className="fa fa-eye"
                          aria-hidden="true"
                          onClick={this.showhidePWD.bind(this)}
                        ></i>
                      </span>
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <div className="focus-out pwd-group">
                    <div className="pwd-group">
                      <input
                        type={this.state.cpasswordType}
                        placeholder="Confirm Password"
                        minlegth="8"
                        className="form-control input-focus"
                        onChange={this.handleChange.bind(
                          this,
                          "confirm_password"
                        )}
                      />
                      {this.state.confirm_passwordError !== "" && (
                        <div className="error">
                          {this.state.confirm_passwordError}
                        </div>
                      )}
                      <span
                        className={
                          this.state.cpasswordType === "text" ? "active" : ""
                        }
                      >
                        <i
                          className="fa fa-eye-slash"
                          aria-hidden="true"
                          onClick={this.showhideCPWD.bind(this)}
                        ></i>
                        <i
                          className="fa fa-eye"
                          aria-hidden="true"
                          onClick={this.showhideCPWD.bind(this)}
                        ></i>
                      </span>
                    </div>
                    <div id="form-msg"></div>
                  </div>
                </div>

                <div className="forgot-login-popup login_pop_sub">
                  <div className="form-button">
                    <button
                      type="button"
                      className="btn btn_black btn_minwid"
                      id="reset-password"
                      onClick={this.resetpasswordSubmit.bind(this)}
                    >
                      Submit
                    </button>
                  </div>
                </div>

                <div className="cp-form-footer">
                  <p>
                    By resetting your password you agree to our{" "}
                    <Link
                      to={"/terms-and-conditions"}
                      target="_blank"
                      className="link"
                    >
                      Terms &amp; conditions,
                    </Link>{" "}
                    and{" "}
                    <Link
                      to={"/privacy-policy"}
                      target="_blank"
                      className="link"
                    >
                      Privacy policy
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <VideoPlayer
            open={this.state.showCustomizeVideo}
            handleClose={() => this.handleWatchVideo()}
          />

          <Footer {...this.props} />
        </>

        <BottomSheet
          open={this.state.openCalendarBottomSheet}
          className="bottomSheetMain"
          onDismiss={() => this.setState({ openCalendarBottomSheet: false })}
        >
          <div id="calendar-date-popup">
            <div
              className="searchContainer__cancelBtn"
              onClick={() => {
                this.setState({ openCalendarBottomSheet: false });
              }}
            >
              <ArrowBackIosIcon className="searchContainer__cancelBtn__backIcon" />{" "}
              Back
            </div>
            <div className="date-popup-menu">
              <div className="step-content-calender textcenter searchContainer__bottomSheetMain__container__date">
                <div className="title">Select dates</div>
                <DateRange
                  showDateDisplay={false}
                  startDatePlaceholder={"Start Date"}
                  endDatePlaceholder={"End Date"}
                  editableDateInputs={false}
                  moveRangeOnFirstSelection={false}
                  ranges={[this.state.selectionRange]}
                  onChange={this.handleSelectDateRangeSelectRange.bind(this)}
                  rangeColors={["#4258bf"]}
                  months={this.state.isMobile ? 12 : 2}
                  minDate={new Date()}
                  direction={this.state.isMobile ? "vertical" : "horizontal"}
                  preventSnapRefocus
                />
                <div className="continue-btn">
                  <a
                    href={void 0}
                    className="button confirm-button"
                    onClick={() => this.closeDatePopup()}
                  >
                    Continue
                  </a>
                </div>
              </div>
            </div>
          </div>
        </BottomSheet>
        <BottomSheet
          open={this.state.openFlightSearchSheet !== null}
          className="bottomSheetMain"
          onDismiss={() => this.setState({ openFlightSearchSheet: null })}
        >
          <div className="search-popup">
            <div
              className="searchContainer__cancelBtn"
              onClick={() => {
                this.setState({ openFlightSearchSheet: null });
              }}
            >
              <ArrowBackIosIcon className="searchContainer__cancelBtn__backIcon" />{" "}
              Back
            </div>
            <div className="destin-title flight-search-main">
              {["from", "to"].includes(this.state.openFlightSearchSheet) && (
                <div className="search-pop">
                  <input
                    type="text"
                    id="search_city"
                    placeholder="Search for Destinations"
                    onChange={(e) => {
                      if (this.state.openFlightSearchSheet === "from") {
                        this.handleCityChange(e, "fromCityInput");
                      } else if (this.state.openFlightSearchSheet === "to") {
                        this.handleCityChange(e, "toCityInput");
                      }
                    }}
                  />
                  <img src={mobsearch} />
                </div>
              )}
              {this.state.searchkeywords !== "" ? (
                <div className="search-results">
                  <div className="search-with-padd">
                    <div className="search-with-img-result mCustomScrollbar">
                      {this.state?.searchCityList !== "" && (
                        <ul>
                          {this.state?.searchCityList.map((item, index) => {
                            return (
                              <li key={index}>
                                <a
                                  href={void 0}
                                  onClick={() => {
                                    this.setState({ searchkeywords: "" });
                                    this.selectNewDestination(item, 3);
                                  }}
                                >
                                  <img
                                    src={
                                      item.thumbnail !== "" &&
                                      item.thumbnail !== null
                                        ? item.thumbnail
                                        : item.images !== "" &&
                                          item.images !== null
                                        ? item.images
                                        : destim
                                    }
                                  />
                                  <span>
                                    <strong>{item.city_name}</strong>
                                    <em>{item.country_name}</em>
                                  </span>
                                </a>
                              </li>
                            );
                          })}
                        </ul>
                      )}
                    </div>
                  </div>
                </div>
              ) : null}
              {this.state.fromCityInput !== "" && (
                <div className="flight-results">
                  {this.state.fromCityList?.searchResults?.map((country) => (
                    <div
                      key={country.id}
                      className="row"
                      onClick={() =>
                        this.setState({
                          source_airport_code: country.airport_code,
                          fromCityList: [],
                          fromCityInput:
                            country.airport_city_name || country?.airport_name,
                          sourceCityItem: country,
                          openFlightSearchSheet: null,
                        })
                      }
                    >
                      <div className="col-1">
                        <div className="city">{country.airport_name}</div>
                        <div className="airport">
                          {country.airport_city_name}
                        </div>
                      </div>
                      <div className="col-2">{country.airport_code}</div>
                    </div>
                  ))}
                </div>
              )}
              {this.state.toCityInput !== "" && (
                <div className="flight-results">
                  {this.state.toCityList?.searchResults
                    ?.filter(
                      (country) =>
                        country.airport_code !== this.state.source_airport_code
                    )
                    ?.map((country) => (
                      <div
                        key={country.id}
                        className="row"
                        onClick={() =>
                          this.setState({
                            destination_airport_code: country.airport_code,
                            toCityInput:
                              country.airport_city_name ||
                              country?.airport_name,
                            toCityList: [],
                            desCityItem: country,
                            openFlightSearchSheet: null,
                          })
                        }
                      >
                        <div className="col-1">
                          <div className="city">{country.airport_name}</div>
                          <div className="airport">
                            {country.airport_city_name}
                          </div>
                        </div>
                        <div className="col-2">{country.airport_code}</div>
                      </div>
                    ))}
                </div>
              )}
              {this.state.openFlightSearchSheet === "fromtripdate" && (
                <div className="flight-results dateMain">
                  {/* {this.state.trip_type === "Return" ? ( */}
                  <Calendar
                    onChange={this.handleSelectDate.bind(this)}
                    minDate={new Date()}
                    date={this.state.start_date}
                    months={this.state.isMobile ? 1 : 2}
                    preventSnapRefocus={true}
                    direction="horizontal"
                  />
                  <div className="btnMain">
                    <Button
                      variant="contained"
                      className="doneBtn"
                      style={{}}
                      onClick={() =>
                        this.setState({
                          openFlightSearchSheet: null,
                        })
                      }
                    >
                      Done
                    </Button>
                  </div>
                </div>
              )}
              {this.state.openFlightSearchSheet === "totripdate" && (
                <div className="flight-results dateMain">
                  <DateRange
                    showDateDisplay={false}
                    startDatePlaceholder={"Departure Date"}
                    endDatePlaceholder={"Return Date"}
                    editableDateInputs={false}
                    moveRangeOnFirstSelection={false}
                    ranges={[this.state.dateRange]}
                    onChange={this.handleSelectDateRange.bind(this)}
                    rangeColors={["#4258bf"]}
                    months={this.state.isMobile ? 1 : 2}
                    minDate={this.state.start_date}
                    direction="horizontal"
                  />

                  <div className="btnMain">
                    <Button
                      variant="contained"
                      className="doneBtn"
                      style={{}}
                      onClick={() =>
                        this.setState({
                          openFlightSearchSheet: null,
                        })
                      }
                    >
                      Done
                    </Button>
                  </div>
                </div>
              )}
              {this.state.openFlightSearchSheet === "passenger" && (
                <div className="flight-results pass-dropdown step-content-passenger">
                  {this.passengerSelection()}
                  <div className="btnMain">
                    <Button
                      variant="contained"
                      className="doneBtn"
                      style={{}}
                      onClick={() =>
                        this.setState({ openFlightSearchSheet: null })
                      }
                    >
                      Done
                    </Button>
                  </div>
                </div>
              )}
              {/* {this.state.searchkeywords === "" && (
                <>
                  <div>
                    <div className="title">Package type</div>
                    <div className="option-row">
                      <div
                        className={`option ${
                          this.state.packageType === "vacation" ? "active" : ""
                        }`}
                        onClick={this.selectPacakgeType.bind(this, "vacation")}
                      >
                        Customizable
                      </div>
                      <div
                        className={`option ${
                          this.state.packageType === "uop" ? "active" : ""
                        }`}
                        onClick={this.selectPacakgeType.bind(this, "uop")}
                      >
                        Curated
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="title">Duration of vacation</div>
                    <div className="option-row">
                      <div
                        className={`option ${
                          this.state.start_date !== "" ? "active" : ""
                        }`}
                        onClick={() =>
                          this.setState({ openCalendarBottomSheet: true })
                        }
                      >
                        {this.state.start_date !== ""
                          ? moment(this.state.start_date).format(
                              "DD MMMM YYYY"
                            ) +
                            " to " +
                            moment(this.state.end_date).format("DD MMMM YYYY")
                          : "Start Date - End Date"}
                      </div>
                    </div>
                    {this.state.error_start_date === true && (
                      <span class="error">
                        Please Select Your vacation date
                      </span>
                    )}
                  </div>
                  <div>
                    <div className="title">Price range</div>
                    <div className="option-row1">
                      {priceFilterOptions?.map((item, index) => (
                        <div
                          className={`option ${
                            item.value === this.state.price ? "active" : ""
                          }`}
                          onClick={this.sateValChange.bind(
                            this,
                            "price",
                            item.value
                          )}
                          key={index}
                        >
                          {item.label}
                        </div>
                      ))}
                    </div>
                  </div>
                  <br />
                  <br />
                  <br />
                  <div className="actions">
                    <Button
                      variant="text"
                      className="clearBtn"
                      onClick={this.clearSearch.bind(this)}
                    >
                      Clear all
                    </Button>
                    <Button
                      variant="contained"
                      className="applyBtn"
                      onClick={this.continueExplore.bind(this)}
                    >
                      Search
                    </Button>
                  </div>
                </>
              )} */}
            </div>
          </div>
        </BottomSheet>
        <BottomSheet
          open={this.state.openSearchBottomSheet}
          className="bottomSheetMain"
          onDismiss={() => this.setState({ openSearchBottomSheet: false })}
        >
          <div className="search-popup">
            <div
              className="searchContainer__cancelBtn"
              onClick={() => {
                this.setState({ openSearchBottomSheet: false });
              }}
            >
              <ArrowBackIosIcon className="searchContainer__cancelBtn__backIcon" />{" "}
              Back
            </div>
            <div className="destin-title">
              <div className="search-pop">
                <input
                  type="text"
                  id="search_city"
                  placeholder="Search for Destinations"
                  onChange={this.handleTextChange.bind(this, "city")}
                  // value={this.state.searchkeywords}
                />
                <img src={mobsearch} />
              </div>
              {this.state.error_location_source === true && (
                <span class="error">Please Select Your destination</span>
              )}
              {this.state.searchkeywords !== "" ? (
                <div className="search-results">
                  <div className="search-with-padd">
                    <div className="search-with-img-result mCustomScrollbar">
                      {this.state?.searchCityList !== "" && (
                        <ul>
                          {this.state?.searchCityList.map((item, index) => {
                            return (
                              <li key={index}>
                                <a
                                  href={void 0}
                                  onClick={() => {
                                    this.setState({ searchkeywords: "" });
                                    this.selectNewDestination(item, 3);
                                  }}
                                >
                                  <img
                                    src={
                                      item.thumbnail !== "" &&
                                      item.thumbnail !== null
                                        ? item.thumbnail
                                        : item.images !== "" &&
                                          item.images !== null
                                        ? item.images
                                        : destim
                                    }
                                  />
                                  <span>
                                    <strong>{item.city_name}</strong>
                                    <em>{item.country_name}</em>
                                  </span>
                                </a>
                              </li>
                            );
                          })}
                        </ul>
                      )}
                    </div>
                  </div>
                </div>
              ) : null}
              {this.state.searchkeywords === "" && (
                <>
                  <div>
                    <div className="title">Package type</div>
                    <div className="option-row">
                      <div
                        className={`option ${
                          this.state.packageType === "vacation" ? "active" : ""
                        }`}
                        onClick={this.selectPacakgeType.bind(this, "vacation")}
                      >
                        Customizable
                      </div>
                      <div
                        className={`option ${
                          this.state.packageType === "uop" ? "active" : ""
                        }`}
                        onClick={this.selectPacakgeType.bind(this, "uop")}
                      >
                        Curated
                      </div>
                    </div>
                  </div>
                  <div>
                    {this.state.packageType === "vacation" && (
                      <>
                        <div className="title">Duration of vacation</div>
                        <div className="option-row">
                          <div
                            className={`option ${
                              this.state.start_date !== "" ? "active" : ""
                            }`}
                            onClick={() =>
                              this.setState({ openCalendarBottomSheet: true })
                            }
                          >
                            {this.state.start_date !== ""
                              ? moment(this.state.start_date).format(
                                  "DD MMMM YYYY"
                                ) +
                                " to " +
                                moment(this.state.end_date).format(
                                  "DD MMMM YYYY"
                                )
                              : "Start Date - End Date"}
                          </div>
                        </div>
                        {this.state.error_start_date === true && (
                          <span class="error">
                            Please Select Your vacation date
                          </span>
                        )}
                      </>
                    )}
                  </div>
                  {this.state.packageType === "uop" && (
                    <>
                      <FormGroup>
                        <div className="title">Month of vacation</div>
                        <div className="month-filter">
                          {nextYearMonths?.map((item, index) => {
                            return (!this.state.showMoreMonths && index < 5) ||
                              this.state.showMoreMonths ? (
                              <div
                                key={index}
                                style={{
                                  color:
                                    this.props.selectedMonth === item?.value
                                      ? "#4258BF"
                                      : "inherit",
                                  fontWeight:
                                    this.props.selectedMonth === item?.value
                                      ? "bold"
                                      : "normal",
                                }}
                                onClick={this.monthFilter.bind(
                                  this,
                                  item?.value
                                )}
                              >
                                <div className="month-filter-font">
                                  {item?.label}
                                </div>
                              </div>
                            ) : null;
                          })}
                          <a
                            onClick={this.toggleShowMore}
                            className="month-showMoreBtn"
                          >
                            {this.state.showMoreMonths
                              ? "Show Less"
                              : "Show More"}
                          </a>
                        </div>
                      </FormGroup>
                      {this.state.error_month === true && (
                        <span class="error">
                          Please Select Your vacation Month
                        </span>
                      )}
                    </>
                  )}
                  <div>
                    <div className="title">Price range</div>
                    <div className="option-row1">
                      {priceFilterOptions?.map((item, index) => (
                        <div
                          className={`option ${
                            item.value === this.state.price ? "active" : ""
                          }`}
                          onClick={this.sateValChange.bind(
                            this,
                            "price",
                            item.value
                          )}
                          key={index}
                        >
                          {item.label}
                        </div>
                      ))}
                    </div>
                  </div>
                  <br />
                  <br />
                  <br />
                  <div className="actions">
                    <Button
                      variant="text"
                      className="clearBtn"
                      onClick={this.clearSearch.bind(this)}
                    >
                      Clear all
                    </Button>
                    <Button
                      variant="contained"
                      className="applyBtn"
                      onClick={this.continueExplore.bind(this)}
                    >
                      Search
                    </Button>
                  </div>
                </>
              )}
            </div>
          </div>
        </BottomSheet>
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  var custdetailArr = Array();
  var common = Array();
  if (Object.keys(state.customerdetail).length > 0) {
    if (state.customerdetail[0].status === "ok") {
      custdetailArr = state.customerdetail[0].result_set;
      common = state.customerdetail[0].customerdetail;
    }
  }
  return {
    customerdetail: custdetailArr,
    common: common,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCustomerDetail: (params) => {
      dispatch({ type: GET_CUSTOMER_DETAIL, params });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Home);
const CustomForm = ({ status, message, onValidated }) => {
  let email;
  const submit = () =>
    email &&
    email.value.indexOf("@") > -1 &&
    onValidated({
      EMAIL: email.value,
    });
  if (status === "sending") {
  } else {
    // hideLoader("submit_subscripe");
  }
  return (
    <div className="newsletter-form">
      <input
        type="email"
        ref={(node) => (email = node)}
        id="email"
        name="EMAIL"
        className="subscripe_email"
        placeholder="Enter your email"
        required
      />
      <button
        type="submit"
        className="button"
        id="submit_subscripe"
        onClick={submit}
      >
        Subscribe
      </button>
      {status === "error" && (
        <div className="error" dangerouslySetInnerHTML={{ __html: message }} />
      )}
      {status === "success" && (
        <div
          className="success"
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
    </div>
  );
};
